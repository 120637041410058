function Linkify({ text, color }) {
  // Regular expression to find URLs within text
  const urlRegex = /(https?:\/\/[^\s()]+)/g;

  // Split the text into parts: URLs and regular text
  const parts = text?.split(urlRegex) || [];

  // Map through the parts to create elements
  const elements = parts?.map((part, index) => {
    if (part?.match(urlRegex)) {
      // If it's a URL, create an <a> tag
      return (
        <a
          style={{ color: color }}
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
        >
          {part}
        </a>
      );
    } else {
      // If it's regular text, just display it
      return <span key={index}>{part}</span>;
    }
  });

  return <>{elements}</>;
}

export default Linkify;
