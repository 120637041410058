import React, { useState, useEffect, useRef } from 'react';
import { Button, Progress, Typography, Space, message, Spin, Avatar, Modal, List, Card, Tooltip, Select, Input } from 'antd';
import { AudioOutlined, ReloadOutlined, ShopOutlined, TrophyOutlined, FireOutlined, StarOutlined, CalendarOutlined, TranslationOutlined, SettingOutlined } from '@ant-design/icons';
import axios from 'axios';
import styled, { keyframes, createGlobalStyle } from 'styled-components';

const { Title, Text } = Typography;
const { Option } = Select;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #f0f2f5;
    font-family: 'Nunito', sans-serif;
  }
`;

const GameContainer = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 20px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 2fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const floatAnimation = keyframes`
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-10px) rotate(5deg); }
  100% { transform: translateY(0px) rotate(0deg); }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); box-shadow: 0 0 0 0 rgba(28, 176, 246, 0.7); }
  70% { transform: scale(1.05); box-shadow: 0 0 0 10px rgba(28, 176, 246, 0); }
  100% { transform: scale(1); box-shadow: 0 0 0 0 rgba(28, 176, 246, 0); }
`;

const AvatarContainer = styled.div`
  width: 200px;
  height: 200px;
  margin: auto;
  animation: ${floatAnimation} 3s ease-in-out infinite;
`;

const StyledAvatar = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #1cb0f6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const VoiceButton = styled(Button)`
  width: 120px;
  height: 120px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: ${props => props.isListening ? '#ff4d4f' : '#1cb0f6'};
  color: white;
  border: none;
  animation: ${props => props.isListening ? pulseAnimation : 'none'} 1.5s infinite;
  transition: all 0.3s ease;
  &:hover, &:focus {
    background-color: ${props => props.isListening ? '#ff7875' : '#40a9ff'};
    color: white;
    transform: scale(1.05);
  }
`;

const ConversationCard = styled(Card)`
  margin-bottom: 16px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
`;

const StreakBadge = styled.div`
  background-color: #ff9500;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
`;

const XpBar = styled(Progress)`
  .ant-progress-bg {
    background-image: linear-gradient(to right, #1cb0f6, #14d8a5);
  }
`;

const GameButton = styled(Button)`
  border-radius: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 20px;
`;

const AchievementBadge = styled.div`
  background-color: ${props => props.color || '#ffd700'};
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  margin: 5px;
`;

const DailyChallenge = styled.div`
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
`;

const Conversation = () => {
  const [messages, setMessages] = useState([]);
  const [isListening, setIsListening] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [score, setScore] = useState(0);
  const [level, setLevel] = useState(1);
  const [topic, setTopic] = useState('');
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [xp, setXp] = useState(0);
  const [streak, setStreak] = useState(0);
  const [showShop, setShowShop] = useState(false);
  const [coins, setCoins] = useState(0);
  const [achievements, setAchievements] = useState([]);
  const [dailyChallenge, setDailyChallenge] = useState(null);
  const [language, setLanguage] = useState('en-US');
  const [showSettings, setShowSettings] = useState(false);
  const [customTopic, setCustomTopic] = useState('');
  const messagesEndRef = useRef(null);
  const recognition = useRef(null);
  const audioRef = useRef(new Audio());

  useEffect(() => {
    if (messages.length === 0) {
      startNewConversation();
    }
    
    setupSpeechRecognition();
    generateDailyChallenge();

    return () => {
      if (recognition.current) {
        recognition.current.abort();
      }
    };
  }, [language]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const setupSpeechRecognition = () => {
    recognition.current = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognition.current.continuous = false;
    recognition.current.lang = language;
    recognition.current.interimResults = true;
    recognition.current.maxAlternatives = 1;

    recognition.current.onresult = handleSpeechResult;
    recognition.current.onend = () => setIsListening(false);
    recognition.current.onerror = (event) => {
      console.error('Speech recognition error', event.error);
      message.error(`Speech recognition error: ${event.error}`);
      setIsListening(false);
    };
  };

  const generateDailyChallenge = () => {
    const challenges = [
      "Complete 5 conversations today",
      "Earn 1000 XP in one session",
      "Achieve a 10-day streak",
      "Use 15 new vocabulary words",
      "Practice for 45 minutes straight",
      "Master 3 different topics",
      "Complete a conversation without any mistakes",
      "Earn 5 achievements",
      "Reach level 10",
      "Win a debate against the AI"
    ];
    setDailyChallenge(challenges[Math.floor(Math.random() * challenges.length)]);
  };

  const startNewConversation = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('/api/openai/start_conversation', { level, language, customTopic });
      setTopic(response.data.topic);
      addMessage(response.data.initialPrompt, 'ai');
      speakMessage(response.data.initialPrompt);
    } catch (error) {
      message.error('Failed to start a new conversation. Please try again.');
    }
    setIsLoading(false);
  };

  const addMessage = (text, sender) => {
    setMessages(prev => [...prev, { text, sender, id: Date.now() }]);
  };

  const handleSpeechResult = async (event) => {
    const userMessage = event.results[0][0].transcript;
    addMessage(userMessage, 'user');
    setIsLoading(true);
    try {
      const response = await axios.post('/api/openai/conversation', { 
        message: userMessage, 
        level, 
        topic,
        language
      });
      addMessage(response.data.reply, 'ai');
      speakMessage(response.data.reply);
      updateScore(response.data.score);
      updateXp(response.data.score * 15);
      updateStreak();
      updateCoins(response.data.score);
      checkAchievements();
    } catch (error) {
      console.error('API Error:', error.response ? error.response.data : error.message);
      message.error('Failed to get AI response. Please try again.');
    }
    setIsLoading(false);
  };

  const toggleListening = () => {
    if (isListening) {
      recognition.current.stop();
    } else {
      recognition.current.start();
    }
    setIsListening(!isListening);
  };

  const speakMessage = async (text) => {
    setIsSpeaking(true);
    try {
      const response = await axios.post('/api/openai/text_to_speech', { text, language }, { responseType: 'arraybuffer' });
      const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
      const audioUrl = URL.createObjectURL(audioBlob);
      audioRef.current.src = audioUrl;
      audioRef.current.onended = () => setIsSpeaking(false);
      audioRef.current.play();
    } catch (error) {
      console.error('Text-to-speech error:', error);
      message.error('Failed to generate speech. Please try again.');
      setIsSpeaking(false);
    }
  };

  const updateScore = (newScore) => {
    setScore(prevScore => {
      const updatedScore = prevScore + newScore;
      if (updatedScore >= 150) {
        setLevel(prevLevel => prevLevel + 1);
        startNewConversation();
        message.success(`Congratulations! You've reached level ${level + 1}!`);
        return 0;
      }
      return updatedScore;
    });
  };

  const updateXp = (amount) => {
    setXp(prevXp => prevXp + amount);
  };

  const updateStreak = () => {
    setStreak(prevStreak => prevStreak + 1);
  };

  const updateCoins = (amount) => {
    setCoins(prevCoins => prevCoins + amount);
  };

  const checkAchievements = () => {
    const newAchievements = [];
    if (level >= 10 && !achievements.includes('Language Master')) {
      newAchievements.push('Language Master');
    }
    if (streak >= 30 && !achievements.includes('Dedicated Learner')) {
      newAchievements.push('Dedicated Learner');
    }
    if (xp >= 10000 && !achievements.includes('XP Champion')) {
      newAchievements.push('XP Champion');
    }
    if (coins >= 5000 && !achievements.includes('Coin Collector')) {
      newAchievements.push('Coin Collector');
    }
    if (newAchievements.length > 0) {
      setAchievements(prev => [...prev, ...newAchievements]);
      message.success(`New achievement(s) unlocked: ${newAchievements.join(', ')}!`);
    }
  };

  const leaderboardData = [
    { name: 'Player 1', xp: 12500 },
    { name: 'Player 2', xp: 11200 },
    { name: 'Player 3', xp: 10000 },
    { name: 'You', xp: xp },
    { name: 'Player 5', xp: 9800 },
  ].sort((a, b) => b.xp - a.xp);

  const shopItems = [
    { name: 'Super XP Boost', price: 100, description: '3x XP for your next 5 conversations' },
    { name: 'Rare Avatar', price: 500, description: 'Unlock a limited edition avatar' },
    { name: 'Streak Shield', price: 200, description: 'Protect your streak for 3 days' },
    { name: 'Topic Unlock', price: 300, description: 'Unlock advanced conversation topics' },
    { name: 'AI Difficulty Boost', price: 400, description: 'Make the AI more challenging for extra rewards' },
  ];

  return (
    <>
      <GlobalStyle />
      <GameContainer>
        <div>
          <Title level={3}>Player Stats</Title>
          <AvatarContainer>
            <StyledAvatar>
              {level}
            </StyledAvatar>
          </AvatarContainer>
          <Text strong>Level: {level}</Text>
          <XpBar percent={(xp % 1500) / 15} showInfo={false} />
          <Text>XP: {xp} / 1500</Text>
          <StreakBadge>
            <FireOutlined style={{ marginRight: '5px' }} /> {streak} days
          </StreakBadge>
          <Text>Coins: {coins}</Text>
          <Title level={4}>Achievements</Title>
          {achievements.map((achievement, index) => (
            <AchievementBadge key={index} color="#1cb0f6">
              <StarOutlined style={{ marginRight: '5px' }} /> {achievement}
            </AchievementBadge>
          ))}
          <DailyChallenge>
            <Title level={5}><CalendarOutlined /> Daily Challenge</Title>
            <Text>{dailyChallenge}</Text>
          </DailyChallenge>
        </div>
        <div>
          <Title level={2} style={{ color: '#1cb0f6', textAlign: 'center' }}>Language Adventure</Title>
          
          <div style={{ 
            height: '400px', 
            overflowY: 'auto', 
            marginBottom: '20px',
            border: '1px solid #e8e8e8',
            borderRadius: '8px',
            padding: '10px'
          }}>
            {messages.map((msg, index) => (
              <ConversationCard
                key={msg.id}
              >
                <Card.Meta
                  avatar={<Avatar src={msg.sender === 'user' ? '/api/placeholder/50/50' : '/api/placeholder/50/50'} />}
                  title={msg.sender === 'user' ? 'You' : 'AI Tutor'}
                  description={msg.text}
                />
              </ConversationCard>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
            {isLoading ? (
              <Spin size="large" />
            ) : (
              <VoiceButton
                icon={<AudioOutlined />}
                onClick={toggleListening}
                isListening={isListening}
                disabled={isSpeaking}
              >
                {isListening ? 'Listening...' : 'Speak'}
              </VoiceButton>
            )}
          </div>
          <Space style={{ width: '100%', justifyContent: 'center' }}>
            <Tooltip title="Start a new conversation">
              <GameButton 
                icon={<ReloadOutlined />} 
                onClick={startNewConversation}
                style={{ backgroundColor: '#58cc02', color: '#ffffff' }}
              >
                New Topic
              </GameButton>
            </Tooltip>
            <Tooltip title="View leaderboard">
              <GameButton 
                icon={<TrophyOutlined />} 
                onClick={() => setShowLeaderboard(true)}
                style={{ backgroundColor: '#ffd700', color: '#000000' }}
              >
                Leaderboard
              </GameButton>
            </Tooltip>
            <Tooltip title="Visit the shop">
              <GameButton 
                icon={<ShopOutlined />} 
                onClick={() => setShowShop(true)}
                style={{ backgroundColor: '#ff9500', color: '#ffffff' }}
              >
                Shop
              </GameButton>
            </Tooltip>
            <Tooltip title="Settings">
              <GameButton 
                icon={<SettingOutlined />} 
                onClick={() => setShowSettings(true)}
                style={{ backgroundColor: '#8e44ad', color: '#ffffff' }}
              >
                Settings
              </GameButton>
            </Tooltip>
          </Space>
        </div>
        <div>
          <Title level={3}>Game Tips</Title>
          <List
            itemLayout="horizontal"
            dataSource={[
              'Use complex sentences and academic vocabulary',
              'Try to incorporate idioms and phrasal verbs',
              'Practice different types of questions',
              'Attempt to explain complex topics',
              'Engage in debates and provide well-reasoned arguments',
              'Experiment with different accents and pronunciations',
              'Focus on subject-specific terminology',
              'Try to correct the AI if it makes a mistake',
              'Aim for longer, more detailed responses',
              'Challenge yourself with advanced topics'
            ]}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  avatar={<StarOutlined style={{ color: '#1cb0f6' }} />}
                  title={item}
                />
              </List.Item>
            )}
          />
        </div>
      </GameContainer>

      <Modal
        title={<><TrophyOutlined /> Leaderboard</>}
        visible={showLeaderboard}
        onCancel={() => setShowLeaderboard(false)}
        footer={null}
      >
        <List
          itemLayout="horizontal"
          dataSource={leaderboardData}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar style={{ backgroundColor: index === 0 ? '#ffd700' : index === 1 ? '#c0c0c0' : index === 2 ? '#cd7f32' : '#1cb0f6' }}>{index + 1}</Avatar>}
                title={<Text strong>{item.name}</Text>}
                description={`XP: ${item.xp}`}
              />
            </List.Item>
          )}
        />
      </Modal>

      <Modal
        title={<><ShopOutlined /> Language Learning Shop</>}
        visible={showShop}
        onCancel={() => setShowShop(false)}
        footer={null}
      >
        <List
          itemLayout="horizontal"
          dataSource={shopItems}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button type="primary" disabled={coins < item.price} onClick={() => {
                  if (coins >= item.price) {
                    setCoins(coins - item.price);
                    message.success(`You bought ${item.name}!`);
                  }
                }}>
                  Buy for {item.price} coins
                </Button>
              ]}
            >
              <List.Item.Meta
                title={item.name}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </Modal>

      <Modal
        title={<><SettingOutlined /> Settings</>}
        visible={showSettings}
        onCancel={() => setShowSettings(false)}
        footer={null}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Text strong>Select Language:</Text>
          <Select 
            style={{ width: '100%' }} 
            value={language} 
            onChange={(value) => {
              setLanguage(value);
              setupSpeechRecognition();
            }}
          >
            <Option value="en-US">English (US)</Option>
            <Option value="es-ES">Spanish (Spain)</Option>
            <Option value="fr-FR">French (France)</Option>
            <Option value="de-DE">German (Germany)</Option>
            <Option value="zh-CN">Chinese (Simplified)</Option>
          </Select>
          <Text strong>Custom Topic:</Text>
          <Input 
            placeholder="Enter a custom conversation topic" 
            value={customTopic} 
            onChange={(e) => setCustomTopic(e.target.value)}
          />
          <Button type="primary" onClick={() => {
            startNewConversation();
            setShowSettings(false);
          }}>
            Start Custom Topic
          </Button>
        </Space>
      </Modal>

      <Modal
        title="Level Up!"
        visible={level > 1}
        onOk={() => setLevel(1)}
        onCancel={() => setLevel(1)}
      >
        <p>Congratulations! You've reached level {level}!</p>
        <p>New features unlocked:</p>
        <ul>
          <li>More challenging conversations</li>
          <li>Advanced vocabulary and grammar</li>
          <li>Complex topics and debates</li>
          <li>Specialized subject discussions</li>
          <li>Cultural and idiomatic expressions</li>
        </ul>
      </Modal>
    </>
  );
};

export default Conversation;