// Action Types + Action Creators
const ADD_QUESTION = 'ADD_QUESTION';
export const addQuestion = (question) => ({
  type: ADD_QUESTION,
  payload: question
});

const REMOVE_QUESTION = 'REMOVE_QUESTION';
export const removeQuestion = (id) => ({
  type: REMOVE_QUESTION,
  payload: id
});

const GET_QUESTIONS = 'GET_QUESTIONS';
export const getQuestions = (id) => ({
  type: GET_QUESTIONS,
  payload: id
});

// Thunks
export const getQuestionsThunk = () => async (dispatch) => {
  const response = await fetch("/api/questions/");

  if (response.ok) {
    const data = await response.json();
    dispatch(getQuestions(data));
  }
}

export const addQuestionThunk = (question) => (dispatch) => {
  // Here you can perform async operations, like API calls, then dispatch the action
  dispatch(addQuestion(question));
};

export const removeQuestionThunk = (id) => (dispatch) => {
  // Here you can perform async operations, like API calls, then dispatch the action
  dispatch(removeQuestion(id));
};

// Reducer
const initialState = [];

const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTIONS:
      return action.payload;
    case ADD_QUESTION:
      return [...state, action.payload];
    case REMOVE_QUESTION:
      return state.filter(q => q.id !== action.payload);
    default:
      return state;
  }
};

export default questionReducer;
