import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Switch, Modal, message, Collapse, Table, Avatar } from 'antd';
import { CheckOutlined, CloseOutlined, QuestionCircleOutlined, StarFilled } from '@ant-design/icons';
import { loadStripe } from "@stripe/stripe-js";
import './PricingPage.css';

const { Panel } = Collapse;

const PricingPage = () => {
  const [isAnnual, setIsAnnual] = useState(false);
  const [compareModalVisible, setCompareModalVisible] = useState(false);
  const [loading, setLoading] = useState({});
  const sessionUser = useSelector((state) => state.session.user);
  const history = useHistory();

  const createSubscription = async (subscription) => {
    if (!sessionUser) {
      history.push("/login", { from: "pricing" });
      return;
    }
    
    setLoading(prev => ({ ...prev, [subscription]: true }));
    try {
      const { publicKey } = await fetch("/api/stripe/config").then(res => res.json());
      const stripe = await loadStripe(publicKey);
      const { sessionId } = await fetch(`/api/stripe/create-checkout-session/${sessionUser.id}/${subscription}`).then(res => res.json());
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error("Subscription error:", error);
      message.error("There was an error processing your request. Please try again.");
    } finally {
      setLoading(prev => ({ ...prev, [subscription]: false }));
    }
  };

  const plans = [
    {
      name: 'Basic',
      monthlyPrice: 0,
      annualPrice: 0,
      features: ['Unlimited questions', 'Level-tailored questions'],
      disabledFeatures: [],
      cta: "Try Now",
      action: () => history.push("/signup"),
    },
    {
      name: 'Plus',
      monthlyPrice: 9.99,
      annualPrice: 99.99,
      features: ['All Basic features', 'PDF Chat', 'Image Chat', 'Text to speech', 'Save questions for review'],
      popular: true,
      cta: "Get Plus",
      action: () => createSubscription("plus"),
    },
    {
      name: 'Pro',
      monthlyPrice: 14.99,
      annualPrice: 149.99,
      features: ['All Plus features', 'Study materials and resources', 'Conversational AI', 'Adaptive assessments', 'Early access to features'],
      cta: "Get Pro",
      action: () => createSubscription("pro"),
    },
  ];

  const testimonials = [
    {
      name: "Sarah L.",
      role: "High School Student",
      avatar: "https://randomuser.me/api/portraits/women/1.jpg",
      text: "Coursect has revolutionized my study routine. The AI-powered questions are incredibly helpful!"
    },
    {
      name: "Michael R.",
      role: "College Freshman",
      avatar: "https://randomuser.me/api/portraits/men/1.jpg",
      text: "The Pro plan's study materials have been a game-changer for my college prep. Highly recommended!"
    },
    {
      name: "Emily T.",
      role: "Parent",
      avatar: "https://randomuser.me/api/portraits/women/2.jpg",
      text: "As a parent, I love how Coursect adapts to my child's learning pace. It's worth every penny!"
    }
  ];

  const faqs = [
    {
      question: "How does the free trial work?",
      answer: "Our Basic plan is always free and includes core features. You can upgrade to Plus or Pro at any time to access advanced features."
    },
    {
      question: "Can I switch between plans?",
      answer: "Yes, you can upgrade, downgrade, or cancel your subscription at any time. Changes will be reflected in your next billing cycle."
    },
    {
      question: "Is there a student discount?",
      answer: "Yes, we offer a 20% discount for students with a valid .edu email address. Contact our support team to apply for the discount."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept all major credit cards, PayPal, and Apple Pay for subscription payments."
    }
  ];

  const comparisonColumns = [
    { title: 'Feature', dataIndex: 'feature', key: 'feature' },
    { title: 'Basic', dataIndex: 'basic', key: 'basic', render: (text) => text ? <CheckOutlined style={{ color: '#52c41a' }} /> : <CloseOutlined style={{ color: '#f5222d' }} /> },
    { title: 'Plus', dataIndex: 'plus', key: 'plus', render: (text) => text ? <CheckOutlined style={{ color: '#52c41a' }} /> : <CloseOutlined style={{ color: '#f5222d' }} /> },
    { title: 'Pro', dataIndex: 'pro', key: 'pro', render: (text) => text ? <CheckOutlined style={{ color: '#52c41a' }} /> : <CloseOutlined style={{ color: '#f5222d' }} /> },
  ];

  const comparisonData = [
    { key: 1, feature: 'Unlimited questions', basic: true, plus: true, pro: true ,chatgpt: false},
    { key: 2, feature: 'Level-tailored questions', basic: true, plus: true, pro: true,chatgpt: false },
    { key: 3, feature: 'PDF Chat', basic: false, plus: true, pro: true },
    { key: 4, feature: 'Image Chat', basic: false, plus: true, pro: true },
    { key: 5, feature: 'Text to speech', basic: false, plus: true, pro: true },
    { key: 6, feature: 'Save questions for review', basic: false, plus: true, pro: true },
    { key: 7, feature: 'Study materials and resources', basic: false, plus: false, pro: true },
    { key: 8, feature: 'Conversational AI', basic: false, plus: false, pro: true },
    { key: 9, feature: 'Adaptive assessments', basic: false, plus: false, pro: true },
    { key: 10, feature: 'Early access to features', basic: false, plus: false, pro: true },
  ];

  return (
    <div className="pricing-page">
      <header className="pricing-header">
        <h1>Elevate Your Learning Journey</h1>
        <p>Choose the plan that fits your goals and unlock your full potential</p>
      </header>

      <section className="pricing-toggle">
        <span>Monthly</span>
        <Switch onChange={(checked) => setIsAnnual(checked)} />
        <span>Annual <span className="savings">Save up to 20%</span></span>
      </section>

      <section className="pricing-cards">
        {plans.map((plan) => (
          <div key={plan.name} className={`pricing-card ${plan.popular ? 'popular' : ''}`}>
            {plan.popular && <div className="popular-badge">Most Popular</div>}
            <h2>{plan.name}</h2>
            <div className="price">
              ${isAnnual ? plan.annualPrice : plan.monthlyPrice}
              <span>/{isAnnual ? 'year' : 'month'}</span>
            </div>
            <ul>
              {plan.features.map((feature) => (
                <li key={feature}><CheckOutlined /> {feature}</li>
              ))}
              {plan.disabledFeatures?.map((feature) => (
                <li key={feature} className="disabled"><CloseOutlined /> {feature}</li>
              ))}
            </ul>
            <Button 
              type="primary" 
              size="large"
              onClick={plan.action}
              loading={loading[plan.name.toLowerCase()]}
            >
              {plan.cta}
            </Button>
          </div>
        ))}
      </section>

      <section className="features-comparison">
        <h2>Compare Plan Features</h2>
        <Button onClick={() => setCompareModalVisible(true)}>View Detailed Comparison</Button>
      </section>

      <section className="testimonials">
        <h2>What Our Users Say</h2>
        <div className="testimonial-grid">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-card">
              <div className="testimonial-header">
                <Avatar src={testimonial.avatar} size={64} />
                <div>
                  <h3>{testimonial.name}</h3>
                  <p>{testimonial.role}</p>
                </div>
              </div>
              <p className="testimonial-text">{testimonial.text}</p>
              <div className="testimonial-rating">
                {[...Array(5)].map((_, i) => (
                  <StarFilled key={i} style={{ color: '#faad14' }} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="faq">
        <h2>Frequently Asked Questions</h2>
        <Collapse>
          {faqs.map((faq, index) => (
            <Panel header={faq.question} key={index}>
              <p>{faq.answer}</p>
            </Panel>
          ))}
        </Collapse>
      </section>

      <section className="cta">
        <h2>Ready to Start Your Learning Journey?</h2>
        <Button type="primary" size="large" onClick={() => history.push("/signup")}>Get Started Now</Button>
      </section>

      <Modal
        title="Detailed Plan Comparison"
        visible={compareModalVisible}
        onCancel={() => setCompareModalVisible(false)}
        footer={null}
        width={800}
      >
        <Table columns={comparisonColumns} dataSource={comparisonData} pagination={false} />
      </Modal>
    </div>
  );
};

export default PricingPage;