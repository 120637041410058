import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import * as performanceActions from "../../store/subjectPerformance";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Typography, Box, CircularProgress, styled } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '10px 20px',
  textTransform: 'none',
  fontWeight: 'bold',
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 10px 4px rgba(255, 105, 135, .3)',
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '15px',
    padding: theme.spacing(2),
  },
}));

function ResetButtonStats() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const resetPerformance = async () => {
    setLoading(true);
    try {
      const response = await axios.post("/api/performances/reset");
      dispatch(performanceActions.getPerformanceThunk());
      handleClose();
      // Consider using a snackbar or toast for success message
      alert(response.data.message);
    } catch (error) {
      if (error.response?.status === 404) {
        alert("No stats to reset for the current user.");
      } else {
        alert("An error occurred while resetting stats.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <StyledButton
        variant="contained"
        color="secondary"
        onClick={handleClickOpen}
        disabled={loading}
        startIcon={<RestartAltIcon />}
      >
        {loading ? <CircularProgress size={24} /> : "Reset All Stats"}
      </StyledButton>
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box display="flex" alignItems="center" gap={1}>
            <WarningIcon color="warning" fontSize="large" />
            <Typography variant="h5" component="span">Reset All Stats</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1">
              Are you sure you want to reset all stats? This action cannot be undone!
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={resetPerformance}
            color="error"
            variant="contained"
            autoFocus
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Reset All Stats"}
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
}

export default ResetButtonStats;
