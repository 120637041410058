
const EDIT_USER = "users/EDIT_USER";
const edit = (user) => ({
  type: EDIT_USER,
  user,
});

export const editUser = (user) => async (dispatch) => {
  const response = await fetch(`/api/users/${user.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });

  if (response.ok) {
    const data = await response.json();
    dispatch(edit(data));
    return data;
  }
};

let initialState = {};
const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_USER: {
      // console.log(action.user.id);
      return {
        ...state,
        [action.user.id]: action.user,
      };
    }

    default:
      return state;
  }
};

export default usersReducer;
