import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Menu, Button, Drawer } from "antd";
import { MenuOutlined, HomeOutlined, DollarOutlined, InfoCircleOutlined, ReadOutlined, DashboardOutlined, TeamOutlined } from "@ant-design/icons";
import "./Header.css";

const Header = ({
  homeRef,
  servicesRef,
  featuresRef,
  aboutUsRef,
  pricingRef,
  contactUsRef,
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const sessionUser = useSelector((state) => state.session.user);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const menuItems = [
    { key: "home", label: "Home", ref: homeRef },
    { key: "services", label: "Features", ref: servicesRef },
    // { key: "features", label: "How it works", ref: featuresRef },
    // { key: "about", label: "About Us", ref: aboutUsRef },
    { key: "pricing", label: "Pricing", to: "/pricing" },
    // { key: "contact", label: "Contact Us", ref: contactUsRef },
    { key: "company", label: "Company", to: "/about" },
    { key: "blog", label: "Blog", to: "/blog" },
    { key: "careers", label: "Careers", to: "/careers" }, // Add this line
    { key: "contact", label: "Contact Us", ref: contactUsRef },
  ];

  const handleNavigation = (key, ref, to) => {
    setDrawerVisible(false);
    if (to) {
      history.push(to);
    } else if (location.pathname !== "/") {
      history.push("/");
      setTimeout(() => {
        ref?.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    } else {
      ref?.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header className={`ms-header ${isScrolled ? "scrolled" : ""}`}>
      <div className="ms-header-container">
        <Link to="/" className="ms-logo" onClick={() => handleNavigation("home", homeRef)}>
          <h1>Coursect</h1>
        </Link>

        <nav className="ms-desktop-menu">
          <Menu mode="horizontal" selectedKeys={[location.pathname]}>
            <Menu.Item key="/" icon={<HomeOutlined />}>
              <a onClick={() => handleNavigation("home", homeRef)}>Home</a>
            </Menu.Item>
            <Menu.Item key="/pricing" icon={<DollarOutlined />}>
              <Link to="/pricing">Pricing</Link>
            </Menu.Item>
            <Menu.Item key="/about" icon={<InfoCircleOutlined />}>
              <Link to="/about">Company</Link>
            </Menu.Item>
            <Menu.Item key="/blog" icon={<ReadOutlined />}>
              <Link to="/blog">Blog</Link>
            </Menu.Item>
            <Menu.Item key="/careers" icon={<TeamOutlined />}>
              <Link to="/careers">Careers</Link>
            </Menu.Item>
          </Menu>
        </nav>

        <div className="ms-header-actions">
          <Button type="primary" className="ms-sign-in-button" icon={sessionUser ? <DashboardOutlined /> : null}>
            {sessionUser ? (
              <Link to="/home">Dashboard</Link>
            ) : (
              <Link to="/login">Sign In</Link>
            )}
          </Button>
          <Button
            icon={<MenuOutlined />}
            className="ms-menu-button"
            onClick={() => setDrawerVisible(true)}
          />
        </div>
      </div>

      <Drawer
        title="Menu"
        placement="right"
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        className="ms-mobile-menu"
      >
        <Menu mode="vertical" selectedKeys={[location.pathname]}>
          {menuItems.map((item) => (
            <Menu.Item key={item.key} icon={item.key === "careers" ? <TeamOutlined /> : null}>
              {item.to ? (
                <Link to={item.to} onClick={() => setDrawerVisible(false)}>
                  {item.label}
                </Link>
              ) : (
                <a onClick={() => handleNavigation(item.key, item.ref)}>
                  {item.label}
                </a>
              )}
            </Menu.Item>
          ))}
          {sessionUser && (
            <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
              <Link to="/home" onClick={() => setDrawerVisible(false)}>
                Dashboard
              </Link>
            </Menu.Item>
          )}
        </Menu>
      </Drawer>
    </header>
  );
};

export default Header;