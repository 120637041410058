import React from "react";
import { Typography, Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import "./CTASection.css";

const { Title, Paragraph } = Typography;

function CTASection({ ctaRef, contactUsRef, title, subtitle, buttonText }) {
  const handleContactClick = () => {
    contactUsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section ref={ctaRef} className="ms-cta-section">
      <div className="ms-container">
        <div className="ms-cta-content">
          <Title level={2} className="ms-cta-title">
            {title.split(' ').map((word, index) => (
              <span key={index} className="ms-title-word">{word} </span>
            ))}
          </Title>
          <Paragraph className="ms-cta-subtitle">{subtitle}</Paragraph>
        </div>
        <div className="ms-cta-action">
          <Button
            type="primary"
            size="large"
            onClick={handleContactClick}
            className="ms-cta-button"
            icon={<ArrowRightOutlined />}
          >
            {buttonText}
          </Button>
        </div>
      </div>
      <div className="ms-cta-background-shape"></div>
    </section>
  );
}

CTASection.defaultProps = {
  title: "Ready to Transform Your Learning?",
  subtitle: "Join Coursect today and experience the future of education. Our AI-powered platform is designed to help you achieve your academic goals.",
  buttonText: "Get Started Now",
};

export default CTASection;