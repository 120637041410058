import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import { 
  Dialog, DialogTitle, DialogContent, DialogActions,
  Typography, Button, IconButton, Box, Paper, Grid
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from "recharts";
import * as performanceActions from "../../store/subjectPerformance";
import CloseIcon from "@mui/icons-material/Close";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ResetButtonStats from "./ResetButtonStats";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[3],
}));

const difficultyColors = {
  easy: "#4CAF50",
  medium: "#FFC107",
  hard: "#F44336"
};

function StatsModal({ open, onClose }) {
  const [index, setIndex] = React.useState(0);
  const userPerformances = useSelector((state) => state.subjectPerformance) || [];
  const user = useSelector((state) => state.session.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(performanceActions.getPerformanceThunk());
  }, [dispatch]);

  let groupedPerformances = [];

  if (Array.isArray(userPerformances)) {
    groupedPerformances = userPerformances.reduce((acc, curr) => {
      const existingSubject = acc.find((item) => item.subject === curr.subject);

      if (existingSubject) {
        existingSubject.difficulties.push(curr);
      } else {
        acc.push({
          subject: curr.subject,
          difficulties: [curr],
        });
      }

      return acc;
    }, []);
  }

  const subjectOrder = ["math", "chemistry", "physics"];

  groupedPerformances.sort((a, b) => {
    return subjectOrder.indexOf(a.subject) - subjectOrder.indexOf(b.subject);
  });

  groupedPerformances.forEach((item) => {
    item.difficulties.sort((a, b) => {
      const order = ["easy", "medium", "hard"];
      return order.indexOf(a.difficulty) - order.indexOf(b.difficulty);
    });
  });

  const defaultPerformances = [
    {
      subject: "math",
      difficulties: [
        {
          difficulty: "easy",
          total_questions: 0,
          correct_answers: 0,
          accuracy_rate: 0,
        },
        {
          difficulty: "medium",
          total_questions: 0,
          correct_answers: 0,
          accuracy_rate: 0,
        },
        {
          difficulty: "hard",
          total_questions: 0,
          correct_answers: 0,
          accuracy_rate: 0,
        },
      ],
    },
    {
      subject: "chemistry",
      difficulties: [
        {
          difficulty: "easy",
          total_questions: 0,
          correct_answers: 0,
          accuracy_rate: 0,
        },
        {
          difficulty: "medium",
          total_questions: 0,
          correct_answers: 0,
          accuracy_rate: 0,
        },
        {
          difficulty: "hard",
          total_questions: 0,
          correct_answers: 0,
          accuracy_rate: 0,
        },
      ],
    },
    {
      subject: "physics",
      difficulties: [
        {
          difficulty: "easy",
          total_questions: 0,
          correct_answers: 0,
          accuracy_rate: 0,
        },
        {
          difficulty: "medium",
          total_questions: 0,
          correct_answers: 0,
          accuracy_rate: 0,
        },
        {
          difficulty: "hard",
          total_questions: 0,
          correct_answers: 0,
          accuracy_rate: 0,
        },
      ],
    },
  ];

  // Merge groupedPerformances and defaultPerformances
  let mergedPerformances = defaultPerformances.map((defaultItem) => {
    let foundItem = groupedPerformances.find(
      (groupedItem) => groupedItem.subject === defaultItem.subject
    );
    if (foundItem) {
      defaultItem.difficulties = defaultItem.difficulties.map(
        (defaultDifficulty) => {
          let foundDifficulty = foundItem.difficulties.find(
            (difficulty) =>
              difficulty.difficulty === defaultDifficulty.difficulty
          );
          return foundDifficulty || defaultDifficulty;
        }
      );
    }
    // Calculate the overall average performance for each subject
    if (defaultItem.difficulties && defaultItem.difficulties.length > 0) {
      const totalQuestions = defaultItem.difficulties.reduce(
        (acc, curr) => acc + curr.total_questions,
        0
      );
      const correctAnswers = defaultItem.difficulties.reduce(
        (acc, curr) => acc + curr.correct_answers,
        0
      );
      defaultItem.overall_average = correctAnswers / totalQuestions;
    } else {
      defaultItem.overall_average = 0;
    }
    return defaultItem;
  });

  const renderBarChart = (difficultyItem) => (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={[
          {
            name: `Level - ${difficultyItem.difficulty}`,
            accuracy: difficultyItem.accuracy_rate * 100,
          },
        ]}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={[0, 100]} />
        <Tooltip formatter={(value) => [`${value.toFixed(1)}%`]} />
        <Legend />
        <Bar 
          dataKey="accuracy" 
          fill={difficultyColors[difficultyItem.difficulty]}
          label={{ position: 'top', formatter: (value) => `${value.toFixed(1)}%` }}
        />
      </BarChart>
    </ResponsiveContainer>
  );

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" component="span">
            <QueryStatsIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
            Analytics For {user.username}
          </Typography>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Button
            startIcon={<ArrowBackIosNewIcon />}
            onClick={() => setIndex((prev) => (prev > 0 ? prev - 1 : 2))}
          >
            Previous
          </Button>
          <Button
            endIcon={<ArrowForwardIosIcon />}
            onClick={() => setIndex((prev) => (prev < 2 ? prev + 1 : 0))}
          >
            Next
          </Button>
        </Box>
        <SwipeableViews index={index} onChangeIndex={setIndex}>
          {mergedPerformances.map((item) => (
            <StyledPaper key={item.subject}>
              <Typography variant="h5" gutterBottom>
                Subject: {item.subject.charAt(0).toUpperCase() + item.subject.slice(1)}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Overall Average: {((item.overall_average || 0) * 100).toFixed(1)}%
              </Typography>
              <Grid container spacing={3}>
                {item.difficulties.map((difficultyItem) => (
                  <Grid item xs={12} md={4} key={difficultyItem.difficulty}>
                    <Typography variant="h6" gutterBottom>
                      {difficultyItem.difficulty.toUpperCase()} Difficulty
                    </Typography>
                    <Typography variant="body2">
                      Correct Answers: {difficultyItem.correct_answers}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Total Questions: {difficultyItem.total_questions}
                    </Typography>
                    {renderBarChart(difficultyItem)}
                  </Grid>
                ))}
              </Grid>
            </StyledPaper>
          ))}
        </SwipeableViews>
      </DialogContent>
      <DialogActions>
        <ResetButtonStats />
        <Button onClick={onClose} startIcon={<CloseIcon />} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default StatsModal;
