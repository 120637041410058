import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: 16,
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  },
}));

const StyledDialogTitle = styled(DialogTitle)({
  background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
  color: "white",
  padding: "24px",
});

const StyledDialogContent = styled(DialogContent)({
  padding: "24px",
});

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 30,
  textTransform: "none",
  fontWeight: 600,
  padding: "10px 24px",
  boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
}));

function SttPopUp({ openStt, setOpenStt }) {
  return (
    <StyledDialog
      open={openStt}
      onClose={() => setOpenStt(false)}
      maxWidth="sm"
      fullWidth
    >
      <StyledDialogTitle>
        <Box display="flex" alignItems="center">
          <LockIcon fontSize="large" style={{ marginRight: 16 }} />
          <Typography variant="h4" component="div" style={{ flexGrow: 1 }}>
            Upgrade Required
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setOpenStt(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography variant="h6" gutterBottom>
          Unlock Speech-to-Text Feature
        </Typography>
        <Typography variant="body1">
          Upgrade your account to access our powerful speech-to-text feature. Enhance your productivity and experience the full potential of our platform.
        </Typography>
      </StyledDialogContent>
      <DialogActions style={{ padding: "16px 24px" }}>
        <StyledButton
          onClick={() => setOpenStt(false)}
          color="primary"
          variant="outlined"
        >
          Maybe Later
        </StyledButton>
        <StyledButton
          onClick={() => {
            // Handle upgrade action
            setOpenStt(false);
          }}
          color="primary"
          variant="contained"
        >
          Upgrade Now
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
}

export default SttPopUp;