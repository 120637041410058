import React, { useState } from "react";
import { TypeAnimation } from "react-type-animation";
import { useHistory } from "react-router-dom";
import { Modal, Button, Row, Col, Typography, Space } from "antd";
import { PlayCircleOutlined, ArrowRightOutlined } from "@ant-design/icons";
import "./HeroSection.css";

const { Title, Paragraph } = Typography;

function HeroSection({ homeRef }) {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const videoRef = React.useRef();

  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => {
    setIsModalOpen(false);
    videoRef.current.pause();
  };

  return (
    <section ref={homeRef} className="ms-hero-section" onMouseOver={() => history.push("/")}>
      <div className="ms-container">
        <Row gutter={[48, 48]} align="middle">
          <Col xs={24} lg={14}>
            <div className="ms-hero-content">
              <Title level={1} className="ms-hero-title">
                <TypeAnimation
                  sequence={[
                    "AI-Powered Education",
                    3000,
                    "Global Learning, Reimagined",
                    3000,
                  ]}
                  wrapper="span"
                  speed={50}
                  repeat={Infinity}
                />
              </Title>
              <Paragraph className="ms-hero-description">
                Elevate your learning journey with Coursect. Personalized AI tutoring, 
                comprehensive materials, and a supportive global community.
              </Paragraph>
              <Space size="large" className="ms-hero-actions">
                <Button type="primary" size="large" icon={<ArrowRightOutlined />}>
                  Get Started
                </Button>
                <Button size="large" icon={<PlayCircleOutlined />} onClick={showModal}>
                  Watch Demo
                </Button>
              </Space>
              <div className="ms-hero-partners">
                <Title level={5}>Trusted by industry leaders:</Title>
                <Space size="large" align="center">
                  <img src="assets/logo-microsoft.jpg" alt="Microsoft for Startups" className="ms-partner-logo" />
                  <img src="assets/Nasdaq_EC_logo.png" alt="Nasdaq Entrepreneurial Center" className="ms-partner-logo" />
                </Space>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={10}>
            <div className="ms-hero-image-wrapper">
              <div className="ms-hero-image">
                <img src="assets/background.png" alt="AI-enhanced education" />
              </div>
              <div className="ms-hero-shape"></div>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        width={1000}
        footer={null}
        centered
      >
        <video
          ref={videoRef}
          autoPlay
          controls
          width="100%"
          src="assets/video.mp4"
        />
      </Modal>
    </section>
  );
}

export default HeroSection;