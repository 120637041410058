import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { UserOutlined, UpCircleOutlined, LogoutOutlined, ProfileOutlined, CrownOutlined, RocketOutlined, BarChartOutlined, DownOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Menu, Layout } from "antd";
import * as sessionAction from "../../store/session";
import StatsModal from "../StatsModal/StatsModal";
import styled, { keyframes } from "styled-components";
import "./Header.css";

const { Header: AntHeader } = Layout;

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`;

const StyledDropdown = styled(Dropdown)`
  .ant-dropdown-trigger {
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 20px;
    transition: all 0.3s ease;
    background-color: rgba(255, 255, 255, 0.1);

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
      transform: translateY(-2px);
    }

    &:active {
      transform: translateY(0);
    }
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  color: white;
`;

const Username = styled.span`
  margin: 0 8px;
  font-weight: 500;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledAvatar = styled(Avatar)`
  transition: all 0.3s ease;

  &:hover {
    animation: ${pulse} 1.5s infinite;
  }
`;

const DropdownIcon = styled(DownOutlined)`
  transition: all 0.3s ease;
  
  ${StyledDropdown}:hover & {
    transform: rotate(180deg);
  }
`;

function Header() {
  const dispatch = useDispatch();
  const history = useHistory();
  const sessionUser = useSelector((state) => state.session.user);
  const [username, setUsername] = useState(sessionUser?.username);
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false);

  useEffect(() => {
    setUsername(sessionUser?.username);
  }, [sessionUser]);

  const handleLogOut = async () => {
    await dispatch(sessionAction.logout());
    history.push("/");
  };

  const createSubscription = async (subscription) => {
    if (!sessionUser) {
      history.push({
        pathname: "/login",
        search: `?isCreateSubscription=true&subscription=${subscription}`,
        state: { isCreateSubscription: true, subscription },
      });
      return;
    }

    try {
      const { publicKey } = await fetch("/api/stripe/config").then(res => res.json());
      const stripe = await loadStripe(publicKey);
      const { sessionId } = await fetch(`/api/stripe/create-checkout-session/${sessionUser.id}/${subscription}`).then(res => res.json());
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error("Subscription error:", error);
    }
  };

  const menuItems = [
    {
      key: "profile",
      label: "Account",
      icon: <ProfileOutlined />,
      onClick: () => history.push("/profile"),
    },
    {
      key: "stats",
      label: "View Stats",
      icon: <BarChartOutlined />,
      onClick: () => setIsStatsModalOpen(true),
    },
    {
      key: "logout",
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: handleLogOut,
    },
  ];

  if (sessionUser?.subscription_tier !== "pro") {
    const upgradeItems = [
      {
        key: "upgrade-plus",
        label: "Upgrade to Plus Plan",
        icon: <CrownOutlined />,
        onClick: () => createSubscription("plus"),
        disabled: sessionUser?.subscription_tier === "plus"
      },
      {
        key: "upgrade-pro",
        label: "Upgrade to Pro Plan",
        icon: <RocketOutlined />,
        onClick: () => createSubscription("pro"),
      }
    ];
    menuItems.splice(2, 0, ...upgradeItems);
  }

  const menu = (
    <Menu items={menuItems} />
  );

  return (
    <>
      <AntHeader className="app-header">
        <div className="header-content">
          <div className="header-left">
            {/* Add any left-aligned content here if needed */}
          </div>
          <div className="header-right">
            <StyledDropdown overlay={menu} trigger={['click']} placement="bottomRight">
              <UserInfo>
                <StyledAvatar icon={<UserOutlined />} />
                <Username>{username}</Username>
                <DropdownIcon />
              </UserInfo>
            </StyledDropdown>
          </div>
        </div>
      </AntHeader>
      <StatsModal open={isStatsModalOpen} onClose={() => setIsStatsModalOpen(false)} />
    </>
  );
}

export default Header;