import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Button, CircularProgress, Radio, RadioGroup,
  FormControlLabel, FormControl, Card, CardContent, Grid, Alert,
  LinearProgress, Container, Paper, useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { Timer, EmojiEvents, School, ArrowBack, ArrowForward } from '@mui/icons-material';

const GameContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: '#f0f8ff',
  borderRadius: theme.spacing(2),
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const ExamButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  width: '200px',
  height: '120px',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  borderRadius: '15px',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 6px 8px rgba(0,0,0,0.15)',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '100px',
    fontSize: '1rem',
  },
}));

const TimerDisplay = styled(Paper)(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
  marginBottom: theme.spacing(2),
}));

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  marginBottom: theme.spacing(2),
}));

const QuestionCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
}));

const examTypes = [
    { 
      name: 'SAT', 
      sections: [
        { name: 'Reading', duration: 65, questions: 52 },
        { name: 'Writing and Language', duration: 35, questions: 44 },
        { name: 'Math (No Calculator)', duration: 25, questions: 20 },
        { name: 'Math (Calculator)', duration: 55, questions: 38 }
      ],
      subject: 'SAT', 
      topic: 'General', 
      difficulty: 'hard' 
    },
    { 
      name: 'ACT', 
      sections: [
        { name: 'English', duration: 45, questions: 75 },
        { name: 'Math', duration: 60, questions: 60 },
        { name: 'Reading', duration: 35, questions: 40 },
        { name: 'Science', duration: 35, questions: 40 }
      ],
      subject: 'ACT', 
      topic: 'General', 
      difficulty: 'hard' 
    },
    {
      name: 'IB Math',
      sections: [
        { name: 'Paper 1', duration: 90, questions: 20 },
        { name: 'Paper 2', duration: 90, questions: 20 }
      ],
      subject: 'Mathematics',
      topic: 'IB',
      difficulty: 'hard'
    },
    {
      name: 'IB Physics',
      sections: [
        { name: 'Paper 1', duration: 60, questions: 30 },
        { name: 'Paper 2', duration: 90, questions: 25 },
        { name: 'Paper 3', duration: 60, questions: 20 }
      ],
      subject: 'Physics',
      topic: 'IB',
      difficulty: 'hard'
    }
  ];
  

function PracticeTest() {
  const dispatch = useDispatch();
  const [selectedExam, setSelectedExam] = useState(null);
  const [examQuestions, setExamQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [examStarted, setExamStarted] = useState(false);
  const [examFinished, setExamFinished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [feedback, setFeedback] = useState('');
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [score, setScore] = useState(0);
  const [questionsPerExam] = useState(30);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    let timer;
    if (examStarted && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeRemaining === 0 && examStarted) {
      finishExam();
    }
    return () => clearInterval(timer);
  }, [examStarted, timeRemaining]);

  const handleExamSelect = (exam) => {
    setSelectedExam(exam);
    setTimeRemaining(exam.duration * 60);
  };

  const startExam = async () => {
    setLoading(true);
    setError('');

    const data = {
      difficulty: selectedExam.difficulty,
      subject: selectedExam.subject,
      topic: selectedExam.topic,
      grade: 'college',
      language: 'English',
      questionType: 'mc',
      num_questions: questionsPerExam,
    };

    try {
      const response = await fetch("/api/openai/exam", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (response.status === 200) {
        const q = await response.json();
        const newExamDictionary = Object.values(q);
        
        let dictArray = new Array(newExamDictionary.length);
        for (let i = 0; i < newExamDictionary.length; i++) {
          const innerArray = Object.values(newExamDictionary[i]);
          dictArray[i] = innerArray[0];
        }
        setCorrectAnswers(dictArray);

        const shuffledExamDictionary = newExamDictionary.map((question) => {
          const questionText = Object.values(question)[4];
          const answers = Object.values(question).slice(0, 4);
          const shuffledAnswers = answers.sort(() => Math.random() - 0.5);

          return {
            ...question,
            answer1: shuffledAnswers[0],
            answer2: shuffledAnswers[1],
            answer3: shuffledAnswers[2],
            answer4: shuffledAnswers[3],
            question: questionText,
          };
        });

        setExamQuestions(shuffledExamDictionary);
        setExamStarted(true);
        setLoading(false);
      } else {
        setError("Failed to generate exam questions. Please try again");
        setLoading(false);
      }
    } catch (error) {
      console.error('Failed to generate practice test:', error);
      setError("Failed to generate exam questions. Please try again");
      setLoading(false);
    }
  };

  const handleAnswer = (questionIndex, answer) => {
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionIndex]: { selectedAnswer: answer },
    }));
  };

  const nextQuestion = () => {
    if (currentQuestion < examQuestions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
    }
  };

  const prevQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion((prev) => prev - 1);
    }
  };

  const finishExam = async () => {
    setExamFinished(true);
    setExamStarted(false);
    
    if (Object.keys(selectedAnswers).length !== examQuestions.length) {
      setError("Please answer all questions before submitting.");
      return;
    }
  
    const data = {
      examDictionary: examQuestions,
      examAnswers: Object.values(selectedAnswers),
      subject: selectedExam.subject,
      difficulty: selectedExam.difficulty,
      grade: 'college',
      language: 'English',
      correct: correctAnswers,
      timeTaken: selectedExam.duration * 60 - timeRemaining,
    };
  
    try {
      setLoading(true);
      const response = await fetch("/api/openai/grade_exam", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
  
      const resp = await response.json();
      setFeedback(resp.feedback);
      setLoading(false);
  
      const correctAnswersCount = resp.correctAnswers || 0;
      setScore(correctAnswersCount);
      
      const xpEarned = Math.floor(100 * (correctAnswersCount / examQuestions.length));
      // TODO: Implement XP update logic
    } catch (error) {
      setLoading(false);
      setError("Failed to grade your exam. Please try again");
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const renderExamSelection = () => (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h4" gutterBottom align="center">Choose Your Quest</Typography>
      <Grid container spacing={2} justifyContent="center">
        {examTypes.map((exam) => (
          <Grid item key={exam.name} xs={12} sm={6} md={3}>
            <ExamButton
              variant="contained"
              color="primary"
              onClick={() => handleExamSelect(exam)}
              startIcon={<School />}
            >
              {exam.name}
            </ExamButton>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  const renderExamStart = () => (
    <Box textAlign="center">
      <Typography variant="h4" gutterBottom>{selectedExam.name} Adventure</Typography>
      <Typography variant="h6" gutterBottom>Quest Duration: {selectedExam.duration} minutes</Typography>
      <Typography variant="body1" gutterBottom>Challenges: {questionsPerExam}</Typography>
      <Button variant="contained" color="primary" onClick={startExam} size="large">
        Begin Quest
      </Button>
    </Box>
  );

  const renderQuestion = () => {
    const currentQ = examQuestions[currentQuestion];
    const progress = ((currentQuestion + 1) / examQuestions.length) * 100;

    return (
      <>
        <ProgressBar variant="determinate" value={progress} />
        <QuestionCard>
          <CardContent>
            <Typography variant="h5" gutterBottom align="center">Challenge {currentQuestion + 1} of {examQuestions.length}</Typography>
            <Typography variant="body1" paragraph>{currentQ.question}</Typography>
            <FormControl component="fieldset" fullWidth>
              <RadioGroup
                value={selectedAnswers[currentQuestion]?.selectedAnswer || ''}
                onChange={(e) => handleAnswer(currentQuestion, e.target.value)}
              >
                {['answer1', 'answer2', 'answer3', 'answer4'].map((answerKey, index) => (
                  <FormControlLabel key={index} value={currentQ[answerKey]} control={<Radio />} label={currentQ[answerKey]} />
                ))}
              </RadioGroup>
            </FormControl>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button onClick={prevQuestion} disabled={currentQuestion === 0} startIcon={<ArrowBack />}>
                {isMobile ? 'Prev' : 'Previous Challenge'}
              </Button>
              {currentQuestion < examQuestions.length - 1 ? (
                <Button onClick={nextQuestion} endIcon={<ArrowForward />}>
                  {isMobile ? 'Next' : 'Next Challenge'}
                </Button>
              ) : (
                <Button onClick={finishExam} variant="contained" color="primary" endIcon={<EmojiEvents />}>
                  {isMobile ? 'Finish' : 'Complete Quest'}
                </Button>
              )}
            </Box>
          </CardContent>
        </QuestionCard>
      </>
    );
  };

  const renderExamFinished = () => (
    <Box textAlign="center">
      <Typography variant="h4" gutterBottom>Quest Completed!</Typography>
      <Typography variant="h5" gutterBottom>Your Score: {score} / {examQuestions.length}</Typography>
      {feedback && <Alert severity="info" sx={{ mt: 2, mb: 2 }}>{feedback}</Alert>}
      <Button variant="contained" color="primary" onClick={() => {
        setSelectedExam(null);
        setExamFinished(false);
        setSelectedAnswers({});
        setFeedback('');
        setScore(0);
      }} startIcon={<EmojiEvents />}>
        Start New Quest
      </Button>
    </Box>
  );

  return (
    <GameContainer maxWidth="md">
      {examStarted && (
        <TimerDisplay>
          <Timer sx={{ mr: 1 }} />
          <Typography variant="h6">Time Remaining: {formatTime(timeRemaining)}</Typography>
        </TimerDisplay>
      )}
      
      {error && <Alert severity="error" sx={{ mt: 2, mb: 2 }}>{error}</Alert>}
      
      {!selectedExam && renderExamSelection()}
      {selectedExam && !examStarted && !examFinished && renderExamStart()}
      {loading && <CircularProgress />}
      {examStarted && !examFinished && renderQuestion()}
      {examFinished && renderExamFinished()}
    </GameContainer>
  );
}

export default PracticeTest;