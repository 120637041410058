import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Row, Col, Form, Input, Button, message } from "antd";
import { PhoneOutlined, MailOutlined, SendOutlined } from "@ant-design/icons";
import "./SubscribeSection.css";

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

function SubscribeSection({ contactUsRef }) {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await fetch("/api/users/contact-us", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      });

      if (response.status === 200) {
        form.resetFields();
        message.success("Thank you for reaching out. Your message has been submitted successfully.");
      } else {
        throw new Error("API error");
      }
    } catch (error) {
      message.error("There was an error submitting your message. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section 
      ref={contactUsRef} 
      className="ms-subscribe-section"
      onMouseOver={() => history.push("/#contactUs")}
    >
      <div className="ms-container">
        <Row gutter={[48, 48]} align="middle" justify="center">
          <Col xs={24} lg={20}>
            <Title level={2} className="ms-section-title">
              Get in <span className="ms-highlight">Touch</span>
            </Title>
            <Paragraph className="ms-section-description">
              Have questions? We're here to help. Contact us using the information below or send us a message.
            </Paragraph>
          </Col>
        </Row>
        <Row gutter={[48, 48]} align="top">
          <Col xs={24} lg={10}>
            <div className="ms-contact-info">
              <div className="ms-contact-item">
                <PhoneOutlined className="ms-contact-icon" />
                <div>
                  <Title level={4}>Phone</Title>
                  <Paragraph>Mon-Fri, 8:00 am to 5:00 pm</Paragraph>
                  <a href="tel:26377952575">(263) 779525756</a>
                </div>
              </div>
              <div className="ms-contact-item">
                <MailOutlined className="ms-contact-icon" />
                <div>
                  <Title level={4}>Email</Title>
                  <Paragraph>We'll respond within 48 hours</Paragraph>
                  <a href="mailto:coursect1@gmail.com">coursect1@gmail.com</a>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={14}>
            <div className="ms-contact-form">
              <Title level={3}>Send us a Message</Title>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <Form.Item name="first_name" rules={[{ required: true, message: "Please input your first name!" }]}>
                      <Input placeholder="First Name" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item name="last_name" rules={[{ required: true, message: "Please input your last name!" }]}>
                      <Input placeholder="Last Name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="email" rules={[{ required: true, type: "email", message: "Please input a valid email!" }]}>
                  <Input placeholder="Email Address" />
                </Form.Item>
                <Form.Item name="body" rules={[{ required: true, message: "Please input your message!" }]}>
                  <TextArea rows={4} placeholder="How can we help you?" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" icon={<SendOutlined />} loading={loading}>
                    Send Message
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
      <div className="ms-background-shape"></div>
    </section>
  );
}

export default SubscribeSection;