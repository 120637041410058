import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import emailjs from "@emailjs/browser";
import { settings } from "../../settings/settings";
import {
  TextField,
  Button,
  Typography,
  Paper,
  Box,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Send as SendIcon } from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(4, 0),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[3],
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    boxShadow: theme.shadows[6],
  },
}));

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EmailFeedbackForm = () => {
  const formRef = useRef(null);
  const { username, email } = useSelector((state) => state.session.user);

  const [formData, setFormData] = useState({
    subject: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs
      .sendForm(
        settings.emailjs_serviceid,
        settings.emailjs_templateid,
        formRef.current,
        settings.emailjs_publickey
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setIsSubmitting(false);
          setSnackbar({
            open: true,
            message: "Feedback submitted successfully! Thank you for your input.",
            severity: "success",
          });
          setFormData({ subject: "", message: "" });
        },
        (err) => {
          console.log("FAILED...", err);
          setIsSubmitting(false);
          setSnackbar({
            open: true,
            message: "There was an error submitting your feedback. Please try again.",
            severity: "error",
          });
        }
      );
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <StyledPaper elevation={3}>
      <StyledForm ref={formRef} onSubmit={handleSubmit}>
        <Typography variant="h4" component="h1" gutterBottom align="center" color="primary">
          
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="center" color="textSecondary">
          Report Bug or Suggest Improvements
        </Typography>

        <input type="hidden" name="name" value={username} />
        <input type="hidden" name="email" value={email} />

        <TextField
          fullWidth
          label="Subject / Topic"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          required
          variant="outlined"
          placeholder="Example: Bug report, not able to generate question"
        />

        <TextField
          fullWidth
          multiline
          rows={6}
          label="Details"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
          variant="outlined"
          placeholder="Example: I am not able to generate a question. I am getting an error message."
        />

        <Box sx={{ position: "relative" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            fullWidth
            size="large"
            endIcon={<SendIcon />}
          >
            {isSubmitting ? "Submitting..." : "Submit Feedback"}
          </Button>
          {isSubmitting && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </StyledForm>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StyledPaper>
  );
};

export default EmailFeedbackForm;