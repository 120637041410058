import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container, Grid, Card, CardContent, Typography, Button, TextField,
  FormControl, InputLabel, Select, MenuItem, Box, Paper, Divider, CircularProgress,
  LinearProgress, Badge, Tooltip, Snackbar, IconButton, Avatar, useTheme, useMediaQuery,
  Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";
import {
  EmojiEvents as TrophyIcon,
  Stars as StarsIcon,
  Timeline as TimelineIcon,
  Close as CloseIcon,
  Calculate as CalculateIcon,
  Science as ScienceIcon,
  Biotech as BiotechIcon,
  LocalFlorist as LocalFloristIcon,
  HistoryEdu as HistoryEduIcon,
  MenuBook as MenuBookIcon,
  Language as LanguageIcon,
  Psychology as PsychologyIcon,
  Public as PublicIcon,
  Palette as PaletteIcon,
  MusicNote as MusicNoteIcon,
  Computer as ComputerIcon,
  EmojiObjects as LightbulbIcon,
  Whatshot as FireIcon
} from "@mui/icons-material";
import confetti from 'canvas-confetti';
import * as sessionActions from "../../store/session";
import * as questionActions from "../../store/userQuestion";
import * as performanceActions from "../../store/subjectPerformance";

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const GameBackground = styled(Box)(({ theme }) => ({
  backgroundImage: 'url("/game-background.jpg")', // Add a suitable background image
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  minHeight: '100vh',
  padding: theme.spacing(4),
}));

const QuestCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: 15,
  boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '5px',
    background: 'linear-gradient(90deg, #ff4b1f, #1fddff)',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
    animation: `${pulse} 0.5s ease-in-out`,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  transition: 'all 0.3s ease-in-out',
  '&.selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    transform: 'scale(1.05)',
  },
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
  },
}));

const TimerDisplay = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 1000,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
}));

const SubjectIcon = styled(Avatar)(({ theme }) => ({
  width: 60,
  height: 60,
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
}));
const SubjectSelectionBackground = styled(Box)(({ theme }) => ({
  backgroundImage: 'url("/subject-selection-background.jpg")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  minHeight: '100vh',
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));
const SubjectCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
  padding: theme.spacing(2),
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

const LevelBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const XPProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 10,
  backgroundColor: theme.palette.grey[300],
  '& .MuiLinearProgress-bar': {
    borderRadius: 10,
    backgroundColor: theme.palette.success.main,
  },
}));

const xpIncrease = keyframes`
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.5); opacity: 0.7; }
  100% { transform: scale(1); opacity: 0; }
`;

const XPIncreaseAnimation = styled('div')`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #4caf50;
  font-weight: bold;
  animation: ${xpIncrease} 1s ease-out;
`;

const AnswerButton = styled(Button)(({ theme, isSelected }) => ({
  transition: 'all 0.3s ease',
  transform: isSelected ? 'scale(1.05)' : 'scale(1)',
  boxShadow: isSelected ? theme.shadows[4] : theme.shadows[1],
}));
const EpicJourneyBackground = styled(Box)(({ theme }) => ({
  backgroundImage: 'url("/epic-journey-background.jpg")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  minHeight: '100vh',
  padding: theme.spacing(4),
  overflowY: 'auto',
}));

const EpicJourneyCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: 15,
  boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
  marginBottom: theme.spacing(3),
}));
function ImmersiveEducationalQuestGame() {
  const [isQuestionRequestVisible, setIsQuestionRequestVisible] = useState(true);
  const [subject, setSubject] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [topic, setTopic] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [feedback, setFeedback] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isGradingLoading, setIsGradingLoading] = useState(false);
  const [questionType, setQuestionType] = useState("fr");
  const [answers, setAnswers] = useState(["", "", "", ""]);
  const [exam, setExam] = useState(false);
  const [examDictionary, setExamDictionary] = useState([{}]);
  const [examAnswers, setExamAnswers] = useState([]);
  const [numQuestions, setNumQuestions] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [examTimer, setExamTimer] = useState(0);
  const [isExamTimerRunning, setIsExamTimerRunning] = useState(false);
  const [userXP, setUserXP] = useState(0);
  const [userLevel, setUserLevel] = useState(1);
  const [questStreak, setQuestStreak] = useState(0);
  const [showLevelUp, setShowLevelUp] = useState(false);
  const [showQuestCompleteMessage, setShowQuestCompleteMessage] = useState(false);
  const [xpGained, setXpGained] = useState(0);
  const [showSubjectSelection, setShowSubjectSelection] = useState(true);
  const [showQuestIntro, setShowQuestIntro] = useState(false);
  const [questTitle, setQuestTitle] = useState("");
  
  const [showEpicJourney, setShowEpicJourney] = useState(false);
  const [showEpicJourneyFeedback, setShowEpicJourneyFeedback] = useState(false);

  const feedbackRef = useRef(null);
  const examTimerRef = useRef(null);

  const user = useSelector((state) => state.session.user);
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(questionActions.getQuestionsThunk());
    dispatch(performanceActions.getPerformanceThunk());
  }, [dispatch]);

  useEffect(() => {
    if (isExamTimerRunning) {
      examTimerRef.current = setInterval(() => {
        setExamTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else {
      clearInterval(examTimerRef.current);
    }

    return () => clearInterval(examTimerRef.current);
  }, [isExamTimerRunning]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const scrollToFeedback = () => {
    feedbackRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const startQuest = () => {
    setShowSubjectSelection(false);
    setShowQuestIntro(true);
    setQuestTitle(`${subject.charAt(0).toUpperCase() + subject.slice(1)} Adventure: ${difficulty.charAt(0).toUpperCase() + difficulty.slice(1)} Quest`);
  };

  const beginQuest = () => {
    setShowQuestIntro(false);
    getQuestion();
  };

  const addXP = (amount) => {
    setXpGained(amount);
    const newXP = userXP + amount;
    setUserXP(newXP);
    checkLevelUp(newXP);
    setTimeout(() => setXpGained(0), 1000);
  };

  const checkLevelUp = (newXP) => {
    const newLevel = Math.floor(newXP / 1000) + 1;
    if (newLevel > userLevel) {
      setUserLevel(newLevel);
      setShowLevelUp(true);
      confetti();
    }
  };

  const handleCorrectAnswer = (isExam = false) => {
    const xpAmount = isExam ? 100 : 20;
    addXP(xpAmount);
    const newStreak = questStreak + 1;
    setQuestStreak(newStreak);
    if (newStreak % 5 === 0) {
      setShowQuestCompleteMessage(true);
    }
  };

  const handleWrongAnswer = () => {
    setQuestStreak(0);
  };

  const getQuestion = async (e) => {
    e?.preventDefault();
    setExam(false);
    if (subject === "" || topic === "" || difficulty === "") {
      setError("Please select all required fields");
      return;
    }

    setLoading(true);
    setAnswer("");
    setError("");
    setFeedback("");
    setQuestion("");

    const data = {
      difficulty,
      subject,
      topic,
      grade: user?.grade,
      language: user?.language,
      questionType,
    };
    try {
      const response = await fetch("/api/openai/generate_question", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      setLoading(false);

      if (response.status === 200) {
        const shuffleArray = (array) => {
          for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
          }
          return array;
        };
        const q = await response.json();
        const correctAnswer = q.answer1;
        const allAnswers = [q.answer1, q.answer2, q.answer3, q.answer4];
        const shuffledAnswers = shuffleArray([...allAnswers]);

        setCorrectAnswer(correctAnswer);
        setQuestion(q.generated_question);
        if (questionType === "mc" || questionType === "fill_blank") {
          setAnswers(shuffledAnswers);
        }
        setIsQuestionRequestVisible(false);
      } else {
        setError("Failed to generate a question for you. Please try again");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      setError("Failed to generate a question for you. Please try again");
    }
  };

  const gradeResponse = async (e) => {
    e.preventDefault();
    setExam(false);
    setError("");
    setFeedback("");
    if (!question) {
      alert("You must generate a question first");
      return;
    }
    if (!answer) {
      alert("Type your answer in order to receive feedback");
    }

    const data = {
      question,
      answer,
      subject,
      difficulty,
      grade: user?.grade,
      language: user?.language,
      correct: correctAnswer,
    };
    try {
      setIsGradingLoading(true);
      const response = await fetch("/api/openai/grade_response", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const resp = await response.json();
      setFeedback(resp.feedback);
      setIsGradingLoading(false);
      scrollToFeedback();
      dispatch(sessionActions.authenticate());
      dispatch(questionActions.getQuestionsThunk());
      dispatch(performanceActions.getPerformanceThunk());

      if (resp.isCorrect) {
        handleCorrectAnswer();
      } else {
        handleWrongAnswer();
      }
    } catch (error) {
      setIsGradingLoading(false);
      setError("Failed to grade your answer. Please try again");
    }
  };

  const getExam = async (e) => {
    e.preventDefault();
    setNumQuestions(0);
    setQuestionType("mc");
    setExam(true);
    setSelectedAnswers({});
    if (subject === "" || topic === "" || difficulty === "") {
      setError("Please select all required fields");
      return;
    }

    const numQuestionsInput = prompt("How many questions would you like in the exam? (max of 15 questions)");
    const numQuestions = parseInt(numQuestionsInput, 10);

    if (isNaN(numQuestions) || numQuestions <= 0 || numQuestions > 15) {
      alert("Please enter a valid number of questions between 1 and 15.");
      return;
    }

    setNumQuestions(numQuestions);
    setLoading(true);
    setAnswer("");
    setError("");
    setFeedback("");
    setQuestion("");

    const data = {
      difficulty,
      subject,
      topic,
      grade: user?.grade,
      language: user?.language,
      questionType: "mc",
      num_questions: numQuestions,
    };

    try {
      const response = await fetch("/api/openai/exam", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      setAnswers(["", "", "", ""]);
      setLoading(false);

      if (response.status === 200) {
        const q = await response.json();
        const newExamDictionary = Object.values(q);
        setExamDictionary(newExamDictionary);
        setIsQuestionRequestVisible(false);

        let dictArray = new Array(newExamDictionary.length);
        for (let i = 0; i < newExamDictionary.length; i++) {
          const innerArray = Object.values(newExamDictionary[i]);
          dictArray[i] = innerArray[0];
        }
        setCorrectAnswers(dictArray);

        const shuffledExamDictionary = newExamDictionary.map((question) => {
          const questionText = Object.values(question)[4];
          const answers = Object.values(question).slice(0, 4);
          const shuffledAnswers = answers.sort(() => Math.random() - 0.5);

          return {
            ...question,
            answer1: shuffledAnswers[0],
            answer2: shuffledAnswers[1],
            answer3: shuffledAnswers[2],
            answer4: shuffledAnswers[3],
            question: questionText,
          };
        });

        setExamDictionary(shuffledExamDictionary);
        setShowEpicJourney(true);
        setIsExamTimerRunning(true);
      } else {
        setError("Failed to generate exam questions. Please try again");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      setError("Failed to generate exam questions. Please try again");
    }
  };

  const handleAnswerClick = (questionIndex, selectedAnswer) => {
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionIndex]: { selectedAnswer },
    }));
  };

  const gradeExam = async (e) => {
    e.preventDefault();
    setIsExamTimerRunning(false);
    if (Object.keys(selectedAnswers).length !== numQuestions) {
      alert("Please answer all questions before submitting.");
      return;
    }
    setExam(false);
    setError("");
    setFeedback("");

    const data = {
      examDictionary,
      examAnswers: Object.values(selectedAnswers),
      subject,
      difficulty,
      grade: user?.grade,
      language: user?.language,
      correct: correctAnswers,
      timeTaken: examTimer,
    };

    try {
      setIsGradingLoading(true);
      const response = await fetch("/api/openai/grade_exam", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      const resp = await response.json();
      setFeedback(resp.feedback);
      setIsGradingLoading(false);
      setShowEpicJourneyFeedback(true); // Add this line
      dispatch(sessionActions.authenticate());
      dispatch(questionActions.getQuestionsThunk());
      dispatch(performanceActions.getPerformanceThunk());

      const correctAnswers = resp.correctAnswers || 0;
      const totalQuestions = examDictionary.length;
      
      if (correctAnswers === totalQuestions) {
        handleCorrectAnswer(true);
      } else {
        addXP(Math.floor(100 * (correctAnswers / totalQuestions))); // Proportional XP
        handleWrongAnswer();
      }
      setShowQuestCompleteMessage(true);
    } catch (error) {
      setIsGradingLoading(false);
      setError("Failed to grade your exam. Please try again");
    }
    setSelectedAnswers({});
  };
  if (showEpicJourney) {
    return (
      <EpicJourneyBackground>
        <Container maxWidth="md">
          <Typography variant="h3" gutterBottom align="center" color="primary" sx={{ fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>
            Epic Journey: {subject.charAt(0).toUpperCase() + subject.slice(1)} Adventure
          </Typography>
          {!showEpicJourneyFeedback && (
            <>
              <TimerDisplay variant="h4" sx={{ textAlign: 'center', mb: 3 }}>
                Time: {formatTime(examTimer)}
              </TimerDisplay>
              {examDictionary.map((question, index) => (
                <EpicJourneyCard key={index}>
                  <Typography variant="h5" gutterBottom>{`Question ${index + 1}: ${question.question}`}</Typography>
                  <Grid container spacing={2}>
                    {['answer1', 'answer2', 'answer3', 'answer4'].map((ansKey) => (
                      <Grid item xs={12} sm={6} key={ansKey}>
                        <AnswerButton
                          fullWidth
                          variant={selectedAnswers[index]?.selectedAnswer === question[ansKey] ? "contained" : "outlined"}
                          onClick={() => handleAnswerClick(index, question[ansKey])}
                          isSelected={selectedAnswers[index]?.selectedAnswer === question[ansKey]}
                        >
                          {question[ansKey]}
                        </AnswerButton>
                      </Grid>
                    ))}
                  </Grid>
                </EpicJourneyCard>
              ))}
              <Box mt={3} display="flex" justifyContent="space-between">
                <Button variant="outlined" onClick={() => {
                  setShowSubjectSelection(true);
                  setShowEpicJourney(false);
                  setExam(false);
                  setIsExamTimerRunning(false);
                  setExamTimer(0);
                  setFeedback("");
                }}>
                  Abandon Quest
                </Button>
                <Button variant="contained" color="primary" onClick={gradeExam}>
                  Complete Epic Journey
                </Button>
              </Box>
            </>
          )}
          {showEpicJourneyFeedback && (
            <EpicJourneyCard sx={{ mt: 3 }}>
              <Typography variant="h4" gutterBottom align="center" color="primary">Epic Journey Completed!</Typography>
              <Typography variant="h5" gutterBottom>Your Performance:</Typography>
              <Typography variant="body1" paragraph>{feedback}</Typography>
              <Box mt={3} display="flex" justifyContent="center">
                <Button variant="contained" color="primary" onClick={() => {
                  setShowEpicJourney(false);
                  setShowEpicJourneyFeedback(false);
                  setShowSubjectSelection(true);
                }}>
                  Return to Subject Selection
                </Button>
              </Box>
            </EpicJourneyCard>
          )}
        </Container>
      </EpicJourneyBackground>
    );
  }

  const topics = {
    math: [
      { key: "algebra", name: "Algebra" },
      { key: "geometry", name: "Geometry" },
      { key: "trigonometry", name: "Trigonometry" },
      { key: "calculus", name: "Calculus" },
      { key: "statistics", name: "Statistics" },
      { key: "probability", name: "Probability" },
      { key: "number_theory", name: "Number Theory" },
      { key: "linear_algebra", name: "Linear Algebra" },
    ],
    physics: [
      { key: "mechanics", name: "Mechanics" },
      { key: "electricity_magnetism", name: "Electricity & Magnetism" },
      { key: "waves_optics", name: "Waves & Optics" },
      { key: "thermodynamics", name: "Thermodynamics" },
      { key: "modern_physics", name: "Modern Physics" },
      { key: "fluid_mechanics", name: "Fluid Mechanics" },
      { key: "quantum_mechanics", name: "Quantum Mechanics" },
      { key: "astrophysics", name: "Astrophysics" },
    ],
    chemistry: [
      { key: "atomic_structure", name: "Atomic Structure" },
      { key: "chemical_bonding", name: "Chemical Bonding" },
      { key: "chemical_reactions", name: "Chemical Reactions" },
      { key: "acids_bases", name: "Acids and Bases" },
      { key: "organic_chemistry", name: "Organic Chemistry" },
      { key: "inorganic_chemistry", name: "Inorganic Chemistry" },
      { key: "biochemistry", name: "Biochemistry" },
      { key: "physical_chemistry", name: "Physical Chemistry" },
    ],
    biology: [
      { key: "cell_biology", name: "Cell Biology" },
      { key: "genetics", name: "Genetics" },
      { key: "ecology", name: "Evolution" },
      { key: "physiology", name: "Physiology" },
    ],
    history: [
      { key: "ancient_history", name: "Ancient History" },
      { key: "medieval_history", name: "Medieval History" },
      { key: "modern_history", name: "Modern History" },
      { key: "world_wars", name: "World Wars" },
      { key: "cold_war", name: "Cold War" },
    ],
    literature: [
      { key: "poetry", name: "Poetry" },
      { key: "prose", name: "Prose" },
      { key: "drama", name: "Drama" },
      { key: "literary_analysis", name: "Literary Analysis" },
      { key: "world_literature", name: "World Literature" },
    ],
    language: [
      { key: "grammar", name: "Grammar" },
      { key: "vocabulary", name: "Vocabulary" },
      { key: "writing", name: "Writing" },
      { key: "reading_comprehension", name: "Reading Comprehension" },
      { key: "linguistics", name: "Linguistics" },
    ],
    psychology: [
      { key: "cognitive_psychology", name: "Cognitive Psychology" },
      { key: "developmental_psychology", name: "Developmental Psychology" },
      { key: "social_psychology", name: "Social Psychology" },
      { key: "abnormal_psychology", name: "Abnormal Psychology" },
      { key: "neuroscience", name: "Neuroscience" },
    ],
    geography: [
      { key: "physical_geography", name: "Physical Geography" },
      { key: "human_geography", name: "Human Geography" },
      { key: "cartography", name: "Cartography" },
      { key: "climate", name: "Climate" },
      { key: "geopolitics", name: "Geopolitics" },
    ],
    art: [
      { key: "art_history", name: "Art History" },
      { key: "painting", name: "Painting" },
      { key: "sculpture", name: "Sculpture" },
      { key: "modern_art", name: "Modern Art" },
      { key: "art_theory", name: "Art Theory" },
    ],
    music: [
      { key: "music_theory", name: "Music Theory" },
      { key: "music_history", name: "Music History" },
      { key: "composition", name: "Composition" },
      { key: "instruments", name: "Instruments" },
      { key: "genres", name: "Genres" },
    ],
    computer_science: [
      { key: "programming", name: "Programming" },
      { key: "data_structures", name: "Data Structures" },
      { key: "algorithms", name: "Algorithms" },
      { key: "databases", name: "Databases" },
      { key: "web_development", name: "Web Development" },
    ],
  };

  const subjects = [
    { key: 'math', name: 'Math', icon: <CalculateIcon /> },
    { key: 'chemistry', name: 'Chemistry', icon: <ScienceIcon /> },
    { key: 'physics', name: 'Physics', icon: <BiotechIcon /> },
    { key: 'biology', name: 'Biology', icon: <LocalFloristIcon /> },
    { key: 'history', name: 'History', icon: <HistoryEduIcon /> },
    { key: 'literature', name: 'Literature', icon: <MenuBookIcon /> },
    { key: 'language', name: 'Languages', icon: <LanguageIcon /> },
    { key: 'psychology', name: 'Psychology', icon: <PsychologyIcon /> },
    { key: 'geography', name: 'Geography', icon: <PublicIcon /> },
    { key: 'art', name: 'Art', icon: <PaletteIcon /> },
    { key: 'music', name: 'Music', icon: <MusicNoteIcon /> },
    { key: 'computer_science', name: 'CS', icon: <ComputerIcon /> },
  ];

  return (
    <GameBackground>
      <Container maxWidth="lg">
        <QuestCard>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <LevelBadge badgeContent={userLevel} color="primary">
              <Avatar sx={{ bgcolor: 'secondary.main', width: 56, height: 56 }}>
                <StarsIcon fontSize="large" />
              </Avatar>
            </LevelBadge>
            <Box>
              <Typography variant="h6">XP: {userXP}</Typography>
              <XPProgress 
                variant="determinate" 
                value={(userXP % 1000) / 10} 
                sx={{ width: 150 }}
              />
            </Box>
          </Box>
          
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="subtitle1">Quest Streak: {questStreak}</Typography>
            <Box>
              {[...Array(5)].map((_, index) => (
                <FireIcon key={index} color={index < (questStreak % 5) ? "error" : "disabled"} />
              ))}
            </Box>
          </Box>
          
          {showSubjectSelection && (
            <>
              <Typography variant="h4" gutterBottom align="center" color="primary" sx={{ fontWeight: 'bold' }}>
                Choose Your Adventure!
              </Typography>
              
              <Box mb={3}>
                <Typography variant="h6" gutterBottom>Select a Subject</Typography>
                <Grid container spacing={2} justifyContent="center">
                  {subjects.map((subj) => (
                    <Grid item xs={4} sm={3} md={2} key={subj.key}>
                      <SubjectCard 
                        onClick={() => {
                          setSubject(subj.key);
                          setTopic("");
                        }}
                        raised={subject === subj.key}
                      >
                        <SubjectIcon>{subj.icon}</SubjectIcon>
                        <Typography variant="body2" align="center">{subj.name}</Typography>
                      </SubjectCard>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel>Topic</InputLabel>
                    <Select value={topic} onChange={(e) => setTopic(e.target.value)}>
                      {topics[subject]?.map((item) => (
                        <MenuItem key={item.key} value={item.key}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel>Difficulty</InputLabel>
                    <Select value={difficulty} onChange={(e) => setDifficulty(e.target.value)}>
                      <MenuItem value="easy">Easy</MenuItem>
                      <MenuItem value="medium">Medium</MenuItem>
                      <MenuItem value="hard">Hard</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel>Quest Type</InputLabel>
                    <Select value={questionType} onChange={(e) => setQuestionType(e.target.value)}>
                      <MenuItem value="mc">Multiple Choice</MenuItem>
                      <MenuItem value="fr">Free Response</MenuItem>
                      <MenuItem value="fill_blank">Fill in the Blank</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Box mt={3} display="flex" justifyContent="center">
                <Tooltip title={`Earn ${difficulty === 'easy' ? 10 : difficulty === 'medium' ? 20 : 30} XP!`}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={startQuest}
                    disabled={!subject || !topic || !difficulty || !questionType}
                    sx={{ mr: 2 }}
                  >
                    Begin Adventure
                  </Button>
                </Tooltip>
                <Tooltip title="Earn bonus XP for completing an exam!">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={getExam}
                    disabled={!subject || !topic || !difficulty}
                  >
                    Epic Journey
                  </Button>
                </Tooltip>
              </Box>
            </>
          )}

          {showQuestIntro && (
            <Box textAlign="center">
              <Typography variant="h4" gutterBottom color="primary" sx={{ fontWeight: 'bold' }}>
                {questTitle}
              </Typography>
              <Typography variant="h6" paragraph>
                Are you ready to embark on an educational adventure?
              </Typography>
              <Button variant="contained" color="primary" onClick={beginQuest}>
                Start Quest
              </Button>
            </Box>
            )}

            {!showSubjectSelection && !showQuestIntro && !exam && (
              <>
                <Typography variant="h5" gutterBottom color="primary" sx={{ fontWeight: 'bold' }}>
                  {questTitle}
                </Typography>
                <Typography variant="h6" gutterBottom>{question}</Typography>
                {questionType === 'mc' && (
                  <Grid container spacing={2}>
                    {answers.map((ans, index) => (
                      <Grid item xs={12} sm={6} key={index}>
                        <AnswerButton
                          fullWidth
                          variant={answer === ans ? "contained" : "outlined"}
                          onClick={() => setAnswer(ans)}
                          isSelected={answer === ans}
                        >
                          {ans}
                        </AnswerButton>
                      </Grid>
                    ))}
                  </Grid>
                )}
                {questionType === 'fr' && (
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    sx={{ mt: 2 }}
                  />
                )}
                {questionType === 'fill_blank' && (
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    sx={{ mt: 2 }}
                  />
                )}
                <Box mt={3} display="flex" justifyContent="space-between">
                  <Button variant="outlined" onClick={() => {
                    setShowSubjectSelection(true);
                    setFeedback("");
                  }}>
                    Change Quest
                  </Button>
                  <Button variant="contained" color="primary" onClick={gradeResponse}>
                    Submit Answer
                  </Button>
                </Box>
              </>
            )}
  
            {exam && (
              <Box>
                <TimerDisplay variant="h6">
                  Time: {formatTime(examTimer)}
                </TimerDisplay>
                {examDictionary.map((question, index) => (
                  <Box key={index} mb={4}>
                    <Typography variant="h6">{`Question ${index + 1}: ${question.question}`}</Typography>
                    <Grid container spacing={2} mt={1}>
                      {['answer1', 'answer2', 'answer3', 'answer4'].map((ansKey) => (
                        <Grid item xs={12} sm={6} key={ansKey}>
                          <AnswerButton
                            fullWidth
                            variant={selectedAnswers[index]?.selectedAnswer === question[ansKey] ? "contained" : "outlined"}
                            onClick={() => handleAnswerClick(index, question[ansKey])}
                            isSelected={selectedAnswers[index]?.selectedAnswer === question[ansKey]}
                          >
                            {question[ansKey]}
                          </AnswerButton>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                ))}
                <Box mt={3} display="flex" justifyContent="space-between">
                  <Button variant="outlined" onClick={() => {
                    setShowSubjectSelection(true);
                    setExam(false);
                    setIsExamTimerRunning(false);
                    setExamTimer(0);
                    setFeedback("");
                  }}>
                    Abandon Quest
                  </Button>
                  <Button variant="contained" color="primary" onClick={gradeExam}>
                    Complete Epic Journey
                  </Button>
                </Box>
              </Box>
            )}
  
            {feedback && !showSubjectSelection && !showQuestIntro && (
              <Box mt={3} ref={feedbackRef}>
                <Typography variant="h6" gutterBottom>Quest Feedback:</Typography>
                <Paper elevation={2} sx={{ p: 2, backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
                  <Typography>{feedback}</Typography>
                </Paper>
              </Box>
            )}
  
            {xpGained > 0 && <XPIncreaseAnimation>+{xpGained} XP</XPIncreaseAnimation>}
          </QuestCard>
          
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={showLevelUp}
            autoHideDuration={6000}
            onClose={() => setShowLevelUp(false)}
            message={`Congratulations! You've reached level ${userLevel}!`}
            action={
              <IconButton size="small" color="inherit" onClick={() => setShowLevelUp(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
          
        </Container>
      </GameBackground>
    );
  }
  
  export default ImmersiveEducationalQuestGame;