import React, { useState } from 'react';
import { Form, Input, Button, Alert } from 'antd';
import { UserOutlined, LockOutlined, SafetyOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import './ForgotPassword.css';

const ForgotPassword = () => {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [userData, setUserData] = useState(null);
  const history = useHistory();

  const onFinishStep1 = (values) => {
    setIsLoading(true);
    setError('');
    // Retrieve user data from local storage
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser && storedUser.username === values.username) {
      setUserData(storedUser);
      setStep(2);
    } else {
      setError('Username not found');
    }
    setIsLoading(false);
  };

  const onFinishStep2 = (values) => {
    setIsLoading(true);
    setError('');
    if (values.securityAnswer.toLowerCase() === userData.securityAnswer.toLowerCase()) {
      setStep(3);
    } else {
      setError('Incorrect answer');
    }
    setIsLoading(false);
  };

  const onFinishStep3 = (values) => {
    setIsLoading(true);
    setError('');
    // Update password in local storage
    const updatedUser = { ...userData, password: values.newPassword };
    localStorage.setItem('user', JSON.stringify(updatedUser));
    setIsLoading(false);
    history.push('/login');
  };

  const renderStep1 = () => (
    <Form name="forgot-password-step1" onFinish={onFinishStep1} layout="vertical">
      <Form.Item
        name="username"
        rules={[{ required: true, message: 'Please enter your username' }]}
      >
        <Input prefix={<UserOutlined />} placeholder="Username" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block loading={isLoading}>
          Next
        </Button>
      </Form.Item>
    </Form>
  );

  const renderStep2 = () => (
    <Form name="forgot-password-step2" onFinish={onFinishStep2} layout="vertical">
      <Alert message={userData.securityQuestion} type="info" showIcon />
      <Form.Item
        name="securityAnswer"
        rules={[{ required: true, message: 'Please enter your answer' }]}
      >
        <Input prefix={<SafetyOutlined />} placeholder="Your answer" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block loading={isLoading}>
          Verify
        </Button>
      </Form.Item>
    </Form>
  );

  const renderStep3 = () => (
    <Form name="forgot-password-step3" onFinish={onFinishStep3} layout="vertical">
      <Form.Item
        name="newPassword"
        rules={[
          { required: true, message: 'Please enter your new password' },
          { min: 8, message: 'Password must be at least 8 characters long' }
        ]}
      >
        <Input.Password prefix={<LockOutlined />} placeholder="New password" />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        dependencies={['newPassword']}
        rules={[
          { required: true, message: 'Please confirm your new password' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords do not match'));
            },
          }),
        ]}
      >
        <Input.Password prefix={<LockOutlined />} placeholder="Confirm new password" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block loading={isLoading}>
          Reset Password
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-box">
        <h1>Reset your password</h1>
        {step === 1 && renderStep1()}
        {step === 2 && renderStep2()}
        {step === 3 && renderStep3()}
        {error && <Alert message={error} type="error" showIcon />}
        <div className="forgot-password-footer">
          <Link to="/login">Back to login</Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;