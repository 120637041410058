import React, { useEffect } from 'react';
import { Row, Col, Card, Button, Statistic, Timeline, Avatar, Carousel } from 'antd';
import { RocketOutlined, GlobalOutlined, TrophyOutlined, TeamOutlined, UserOutlined, ClockCircleOutlined } from '@ant-design/icons';
import './AboutCompany.css';

const AboutCompany = () => {
  const values = [
    { icon: <RocketOutlined />, title: 'Innovation', description: 'Pushing the boundaries of EdTech' },
    { icon: <GlobalOutlined />, title: 'Accessibility', description: 'Quality education for everyone, everywhere' },
    { icon: <TrophyOutlined />, title: 'Excellence', description: 'Setting the gold standard in online learning' },
    { icon: <TeamOutlined />, title: 'Community', description: 'Fostering a global network of learners' },
  ];

  const team = [
    {
      name: 'Warren Noubi',
      role: 'CEO & Founder',
      image: "https://th.bing.com/th/id/OIP.x_xjD3EgFtuBYO-Holx2gAAAAA?rs=1&pid=ImgDetMain",
      statement: "Education is the most powerful tool we have to change the world. At Coursect, we're making that tool accessible to everyone."
    },
    {
      name: 'Jack Zhang',
      role: 'CTO',
      image: 'https://media.licdn.com/dms/image/v2/D4E03AQGHL1gcOALmYg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1710010897149?e=1730937600&v=beta&t=GCocZ54pMAV9t4PG0w-Y2ZCA_O5jp0nRwQfOqGHMgZs',
      statement: "We're not just building a platform; we're crafting personalized learning experiences that adapt to each individual's needs and aspirations."
    },
    {
      name: 'Ben Shain',
      role: 'Machine Learning Engineer',
      image: 'https://th.bing.com/th/id/OIP.QREjt4i993soLmQBkV--ygAAAA?rs=1&pid=ImgDetMain',
      statement: "Our AI doesn't replace teachers; it empowers them. We're creating a symbiosis between human expertise and machine intelligence."
    },
  ];

  const milestones = [
    { date: 'January 2024', event: 'Coursect founded', icon: '🚀' },
    { date: 'February 2024', event: 'Nasdaq Milestone Maker', icon: '💰' },
    { date: 'March 2024', event: 'Secured Microsoft AI grant', icon: '💰' },
    { date: 'April 2024', event: 'Launched beta version with 5 courses', icon: '🎓' },
    { date: 'August 2024', event: 'Reached 300  users', icon: '👥' },

  ];

  useEffect(() => {
    const timeline = document.querySelector('.timeline-container');
    let isDown = false;
    let startX;
    let scrollLeft;

    timeline.addEventListener('mousedown', (e) => {
      isDown = true;
      startX = e.pageX - timeline.offsetLeft;
      scrollLeft = timeline.scrollLeft;
    });

    timeline.addEventListener('mouseleave', () => {
      isDown = false;
    });

    timeline.addEventListener('mouseup', () => {
      isDown = false;
    });

    timeline.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - timeline.offsetLeft;
      const walk = (x - startX) * 2;
      timeline.scrollLeft = scrollLeft - walk;
    });
  }, []);

  return (
    <div className="about-company">
      <section className="hero">
        <h1>Revolutionizing Education</h1>
        <p>Empowering learners worldwide through cutting-edge technology</p>
      </section>

      <section className="mission">
        <Row gutter={[16, 16]} align="middle">
          <Col xs={24} md={12}>
            <h2>Our Mission</h2>
            <p>At Coursect, we're on a mission to democratize education. We're leveraging AI and innovative learning methodologies to create personalized, engaging, and effective online courses that adapt to each learner's unique needs and pace.</p>
          </Col>
          <Col xs={24} md={12}>
            <img src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80" alt="Mission" className="mission-image" />
          </Col>
        </Row>
      </section>

      <section className="values">
        <h2>Our Core Values</h2>
        <Row gutter={[16, 16]}>
          {values.map((value, index) => (
            <Col xs={24} sm={12} md={6} key={index}>
              <Card className="value-card" hoverable>
                {React.cloneElement(value.icon, { className: 'value-icon' })}
                <h3>{value.title}</h3>
                <p>{value.description}</p>
              </Card>
            </Col>
          ))}
        </Row>
      </section>

      <section className="team">
        <h2>Meet Our Visionaries</h2>
        <Row gutter={[16, 16]}>
          {team.map((member, index) => (
            <Col xs={24} sm={12} md={8} key={index}>
              <Card className="team-card" hoverable>
                <Avatar size={128} src={member.image} />
                <h3>{member.name}</h3>
                <p className="role">{member.role}</p>
                <p className="statement">"{member.statement}"</p>
              </Card>
            </Col>
          ))}
        </Row>
      </section>

      <section className="stats">
        <Row gutter={[16, 16]}>
          {[
            { title: "Active Learners", value: 300, suffix: "+" },
            { title: "Subjects", value: 20, suffix: "+" },
            { title: "Countries", value: 10 },
            { title: "Satisfaction Rate", value: 95, suffix: "%" }
          ].map((stat, index) => (
            <Col xs={12} sm={12} md={6} key={index}>
              <Card className="stat-card">
                <Statistic title={stat.title} value={stat.value} suffix={stat.suffix} />
              </Card>
            </Col>
          ))}
        </Row>
      </section>
      <section className="milestones">
    <h2>Our Journey</h2>
    <div className="timeline-container">
        <div className="timeline-track">
        {milestones.map((milestone, index) => (
            <div key={index} className="timeline-item">
            <div className="timeline-content">
                <div className="timeline-icon">{milestone.icon}</div>
                <h3>{milestone.date}</h3>
                <p>{milestone.event}</p>
            </div>
            <div className="timeline-point"></div>
            </div>
        ))}
        </div>
    </div>
    </section>



      <section className="testimonials">
        <h2>What Our Learners Say</h2>
        <Carousel autoplay>
          <div>
            <p>"Coursect has transformed the way I learn. The personalized approach and AI-driven recommendations have helped me achieve my goals faster than I ever thought possible."</p>
            <h4>Sarah J., Data Science Student</h4>
          </div>
          <div>
            <p>"As an educator, I'm impressed by how Coursect combines technology with sound pedagogical principles. It's not just about content delivery; it's about effective learning."</p>
            <h4>Dr. Michael L., Education Technology Researcher</h4>
          </div>
          <div>
            <p>"The global community on Coursect is incredible. I've connected with learners and experts from around the world, expanding my horizons beyond just course material."</p>
            <h4>Aisha M., Business Analytics Professional</h4>
          </div>
        </Carousel>
      </section>

      <section className="cta">
        <h2>Ready to Transform Your Learning Journey?</h2>
        <p>Join thousands of learners who are already experiencing the future of education with Coursect.</p>
        <Button type="primary" size="large" href="/courses">Explore Our Courses</Button>
      </section>
    </div>
  );
};

export default AboutCompany;
