import React from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Typography, Button } from "antd";
import { EditOutlined, BookOutlined, RobotOutlined } from "@ant-design/icons";
import "./ServicesSection.css";

const { Title, Paragraph } = Typography;

function ServicesSection({ servicesRef }) {
  const history = useHistory();

  const services = [
    {
      icon: <EditOutlined className="service-icon" />,
      title: "Exam Excellence",
      description: "AI-powered exam preparation for AP classes, IB exams, and GCSE, helping students excel in their academic pursuits.",
    },
    {
      icon: <BookOutlined className="service-icon" />,
      title: "Comprehensive Coverage",
      description: "Extensive subject coverage including high school and undergraduate topics in mathematics, physics, chemistry, and more.",
    },
    {
      icon: <RobotOutlined className="service-icon" />,
      title: "AI-Powered Support",
      description: "Personalized learning experience with tailored practice questions, study materials, and a supportive AI-driven environment.",
    },
  ];

  return (
    <section 
      ref={servicesRef} 
      className="ms-services-section"
      onMouseOver={() => history.push("/#services")}
    >
      <div className="ms-container">
        <Row justify="center" gutter={[0, 48]}>
          <Col xs={24} lg={18}>
            <Title level={2} className="ms-section-title">
              Revolutionize Your <span className="ms-highlight">Learning</span>
            </Title>
            <Paragraph className="ms-section-description">
              Empower your academic journey with AI-driven education, tailored practice, and comprehensive support.
            </Paragraph>
          </Col>
        </Row>

        <Row gutter={[48, 48]} className="ms-services-row">
          {services.map((service, index) => (
            <Col xs={24} md={8} key={index}>
              <div className="ms-service-card">
                <div className="ms-service-icon-wrapper">{service.icon}</div>
                <Title level={3} className="ms-service-title">{service.title}</Title>
                <Paragraph className="ms-service-description">{service.description}</Paragraph>
              </div>
            </Col>
          ))}
        </Row>

        <Row justify="center" className="ms-cta-row">
          <Col>
            <Button type="primary" size="large" onClick={() => history.push("/register")}>
              Start Your Journey
            </Button>
          </Col>
        </Row>
      </div>
      <div className="ms-background-shape"></div>
    </section>
  );
}

export default ServicesSection;