import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { styled } from "@mui/material/styles";
import "./TtsPopUp.css";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    boxShadow: '0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.20)',
  },
}));

function TtsPopUp({ openTts, setOpenTts }) {
  return (
    <StyledDialog
      open={openTts}
      onClose={() => setOpenTts(false)}
      maxWidth="sm"
      fullWidth
    >
      <Box className="tts-dialog-header">
        <VolumeUpIcon className="tts-icon" />
        <DialogTitle className="tts-dialog-title">
          <Typography variant="h4">Unlock Text-to-Speech</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenTts(false)}
          className="tts-close-button"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent className="tts-dialog-content">
        <Typography variant="h6" gutterBottom>
          Elevate Your Experience with Text-to-Speech
        </Typography>
        <Typography variant="body1">
          Upgrade your account to access our powerful text-to-speech feature. 
          Transform written content into natural-sounding audio, enhancing accessibility 
          and offering a new dimension to your content consumption.
        </Typography>
        <Box className="tts-features">
          <Typography variant="body1">• Natural-sounding voices</Typography>
          <Typography variant="body1">• Multiple language support</Typography>
          <Typography variant="body1">• Adjustable speech rate</Typography>
          <Typography variant="body1">• Easy integration with your workflow</Typography>
        </Box>
      </DialogContent>
      <DialogActions className="tts-dialog-actions">
        <Button 
          onClick={() => setOpenTts(false)} 
          className="tts-button tts-button-secondary"
        >
          Maybe Later
        </Button>
        <Button 
          onClick={() => {
            // Handle upgrade logic here
            setOpenTts(false);
          }} 
          className="tts-button tts-button-primary"
        >
          Upgrade Now
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default TtsPopUp;