// src/components/Home.js

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import {
  Layout,
  Menu,
  Typography,
  Button,
  Tooltip,
  Tour,
  Alert,
} from "antd";
import {
  QuestionCircleOutlined,
  FileImageOutlined,
  VideoCameraOutlined,
  CommentOutlined,
  FormOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import GenerateQuestion from "../../components/dashboard/GenerateQuestion";
import ImageChat from "../../components/dashboard/ImageChat";
import Conversation from "../../components/dashboard/Conversation";
import PracticeTest from "../../components/dashboard/PracticeTest";
import Header from "../../components/dashboard/Header";
import EmailFeedbackForm from "../../components/EmailFeedbackForm/EmailFeedbackForm";

const { Sider, Content } = Layout;
const { Title } = Typography;

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`;

const StyledSider = styled(Sider)`
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, #001529, #003a8c);
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.div`
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 1px;
`;

const HeaderWrapper = styled.div`
  padding: 16px;
  background: rgba(255, 255, 255, 0.1);
`;

const StyledMenu = styled(Menu)`
  flex: 1;
  background: transparent;
  border-right: none;

  .ant-menu-item {
    margin: 10px 0;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
`;

const StyledContent = styled(Content)`
  padding: 24px;
  background: #f0f2f5;
`;

const ContentCard = styled.div`
  background: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

function Home() {
  const [selectedKey, setSelectedKey] = useState("1");
  const [tourOpen, setTourOpen] = useState(false);

  const sessionUser = useSelector((state) => state.session.user);
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location?.search);
  const subscriptionStatus = params.get("subscription_status");

  if (!sessionUser) return <Redirect to="/login" />;

  const steps = [
    {
      title: "Generate Question",
      description: "Get AI questions on any subject and difficulty.",
      target: () => document.querySelector('.generate-question'),
      placement: 'bottom',
    },
    {
      title: "File Chat",
      description: "Upload images or PDFs to chat with AI about them.",
      target: () => document.querySelector('.image-chat'),
      placement: 'bottom',
    },
    {
      title: "AI Conversation",
      description: "Have a voice conversation with our AI language partner.",
      target: () => document.querySelector('.ai-conversation'),
      placement: 'bottom',
    },
    {
      title: "Practice Test",
      description: "Take a full-length practice test to prepare for exams.",
      target: () => document.querySelector('.practice-test'),
      placement: 'bottom',
    },
    {
      title: "Feedback",
      description: "Help us improve! Report issues or suggest ideas.",
      target: () => document.querySelector('.email-feedback'),
      placement: 'bottom',
    },
  ];

  const renderContent = () => {
    switch (selectedKey) {
      case "1":
        return <GenerateQuestion />;
      case "2":
        return <ImageChat />;
      case "3":
        return <Conversation />;
      case "4":
        return <PracticeTest />;
      case "5":
        return <EmailFeedbackForm />;
      default:
        return null;
    }
  };

  return (
    <StyledLayout>
      <StyledSider width={250} breakpoint="lg" collapsedWidth="0">
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <StyledMenu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedKey]}
          onSelect={({ key }) => setSelectedKey(key)}
        >
          <Menu.Item key="1" icon={<QuestionCircleOutlined />} className="generate-question">
            Generate Question
          </Menu.Item>
          <Menu.Item key="2" icon={<FileImageOutlined />} className="image-chat">
            Image / PDF Chat
          </Menu.Item>
          <Menu.Item key="3" icon={<CommentOutlined />} className="ai-conversation">
            AI Conversation
          </Menu.Item>
          <Menu.Item key="4" icon={<FormOutlined />} className="practice-test">
            Practice Test
          </Menu.Item>
          <Menu.Item key="5" icon={<FileImageOutlined />} className="email-feedback">
            Feedback
          </Menu.Item>
        </StyledMenu>
      </StyledSider>
      <Layout>
        <StyledContent>
          <ContentCard>
            <Title level={2} style={{ marginBottom: 24 }}>
              {selectedKey === "1" 
                ? "Guild Board" 
                : selectedKey === "2" 
                  ? "Upload Your Scroll"
                  : selectedKey === "3"
                    ? "Converse with the Guildmaster"
                    : selectedKey === "4"
                      ? "Take a Practice Test"
                      : "Submit a Quest Log"}
            </Title>
            {subscriptionStatus === "success" && (
              <Alert
                message="Your subscription has been processed successfully"
                type="success"
                closable
                style={{ marginBottom: 24 }}
                afterClose={() => history.push("/home")}
              />
            )}
            {subscriptionStatus === "cancelled" && (
              <Alert
                message="Your subscription failed"
                type="error"
                closable
                style={{ marginBottom: 24 }}
                afterClose={() => history.push("/home")}
              />
            )}
            {renderContent()}
          </ContentCard>
          <Tooltip title="Take a tour">
            <Button
              type="primary"
              shape="circle"
              icon={<VideoCameraOutlined />}
              size="large"
              onClick={() => setTourOpen(true)}
              style={{ position: 'fixed', bottom: 24, right: 24 }}
            />
          </Tooltip>
        </StyledContent>
      </Layout>
      <Tour open={tourOpen} onClose={() => setTourOpen(false)} steps={steps} />
    </StyledLayout>
  );
}

export default Home;