import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Spin, Alert } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link, Redirect, useLocation, useHistory } from "react-router-dom";
import { notification } from "antd";
import { loadStripe } from "@stripe/stripe-js";
import { login } from "../../store/session";
import "./Login.css";

const Login = () => {
  const sessionUser = useSelector((state) => state.session.user);
  const dispatch = useDispatch();
  const [api] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState([]);
  const location = useLocation();
  const history = useHistory();

  if (sessionUser) {
    if (location?.state?.isCreateSubscription) {
      fetch("/api/stripe/config")
        .then((result) => result.json())
        .then(async (data) => {
          const stripe = await loadStripe(data.publicKey);
          fetch(`/api/stripe/create-checkout-session/${sessionUser.id}/${location?.state?.subscription}`)
            .then((result) => result.json())
            .then((data) => {
              console.log(data);
              return stripe.redirectToCheckout({ sessionId: data.sessionId });
            })
            .then((res) => { console.log(res); });
        });
    } else {
      return <Redirect to="/home" />;
    }
  }

  const onFinish = async (values) => {
    setError([]);
    setIsLoading(true);
    try {
      const data = await dispatch(login(values.username, values.password));
      if (data) {
        console.log("***", data);
        setError(data);
      }
    } catch (e) {
      api.error({
        message: "Error",
        description: "Failed to authenticate",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src="/path-to-your-logo.png" alt="Coursect Logo" className="logo" />
        <h1>Sign In to Your Account</h1>
        
        {location?.state?.isCreateSubscription && (
          <Alert
            message="Login or signup first to proceed with your subscription"
            type="info"
            className="subscription-alert"
          />
        )}

        <Form name="login" onFinish={onFinish} layout="vertical">
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your user email!" }]}
          >
            <Input prefix={<UserOutlined />} placeholder="User email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={isLoading}>
              Sign In
            </Button>
          </Form.Item>
        </Form>

        {error.length > 0 && (
          <Alert message={error.join(", ")} type="error" showIcon className="error-alert" />
        )}

        <div className="login-footer">
          Don't have an account yet?{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (location?.state?.isCreateSubscription) {
                const subscription = location?.state?.subscription;
                history.push({
                  pathname: "/register",
                  search: `?isCreateSubscription=true&subscription=${subscription}`,
                  state: { isCreateSubscription: true, subscription },
                });
              } else {
                history.push("/register");
              }
            }}
            className="login-link"
          >
            Sign Up
          </a>
          <br />
          Or
          <br />
          <Link to="/" className="login-link">
            Go back to the landing page
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;