import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { authenticate } from "./store/session";
import MainLayout from "./layouts/MainLayout";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import ProfilePage from "./pages/profile/ProfilePage";
import Signup from "./pages/signup/Signup";
import Debug from "./components/Debug";
import LandingPage from "./pages/landing";
import PricingPage from "./pages/PricingPage";
import AboutCompany from "./pages/AboutCompany"; // Add this import
import BlogPage from "./pages/BlogPage";
import ForgotPassword from './pages/ForgotPassword'; // Add this import
import CareerPage from './pages/CareerPage'; // Add this import
function App() {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    dispatch(authenticate()).then(() => setIsLoaded(true));
  }, [dispatch]);

  if (!isLoaded) {
    return null; // Or a loading spinner
  }

  return (
    <MainLayout>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/register" component={Signup} />
        <Route path="/debug" component={Debug} />
        <Route path="/profile" component={ProfilePage} />
        <Route path="/home" component={Home} />
        <Route path="/pricing" component={PricingPage} />
        <Route path="/about" component={AboutCompany} /> {/* Add this line */}
        <Route path="/blog" component={BlogPage} /> {/* Add this line */}
        <Route path="/forgot-password" component={ForgotPassword} /> {/* Add this line */}
        <Route path="/careers" component={CareerPage} /> {/* Add this line */}
        <Route exact path="/" component={LandingPage} />
      </Switch>
    </MainLayout>
  );
}

export default App;