import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Row, Col, Button, Typography, Divider, Avatar, Statistic, Tabs, Tooltip } from "antd";
import {
  UserOutlined, MailOutlined, BookOutlined, GlobalOutlined,
  TranslationOutlined, FlagOutlined, IdcardOutlined, CalendarOutlined,
  EditOutlined, TrophyOutlined, PercentageOutlined
} from "@ant-design/icons";
import * as performanceActions from "../../store/subjectPerformance";
import UpdateUserModal from "../../components/UpdateUserModal/UpdateUserModal"; // Import the UpdateUserModal component
import "./ProfilePage.css";

const { Title, Text } = Typography;
const { TabPane } = Tabs;

function ProfilePage() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const userPerformances = useSelector((state) => state.subjectPerformance) || [];
  const [stats, setStats] = useState({ 
    totalQuestions: 0, 
    accuracy: 0, 
    streak: 0, 
    percentile: 0 
  });
  const [editModalOpen, setEditModalOpen] = useState(false);

  useEffect(() => {
    dispatch(performanceActions.getPerformanceThunk());
  }, [dispatch]);

  useEffect(() => {
    if (userPerformances.length > 0) {
      const totalQuestions = userPerformances.reduce((sum, perf) => sum + perf.total_questions, 0);
      const totalCorrectAnswers = userPerformances.reduce((sum, perf) => sum + perf.correct_answers, 0);
      const accuracy = totalQuestions > 0 ? (totalCorrectAnswers / totalQuestions) * 100 : 0;
      
      // Calculate percentile based on accuracy and number of questions
      const percentile = calculatePercentile(accuracy, totalQuestions);

      // Note: Streak calculation might need to be implemented separately
      const streak = 0; // Placeholder value

      setStats({
        totalQuestions,
        accuracy,
        streak,
        percentile
      });
    }
  }, [userPerformances]);

  // Function to calculate percentile based on accuracy and number of questions
  const calculatePercentile = (accuracy, totalQuestions) => {
    // This is a simulated percentile calculation. In a real application,
    // this data should come from the server after comparing with all users.
    const score = accuracy * Math.log(totalQuestions + 1);
    const maxPossibleScore = 100 * Math.log(1000); // Assuming 100% accuracy on 1000 questions is top performance
    const percentile = (score / maxPossibleScore) * 100;
    return Math.min(99.9, percentile); // Cap at 99.9th percentile
  };

  const handleEditProfile = () => {
    setEditModalOpen(true);
  };

  return (
    <div className="profile-page">
      <Row gutter={[24, 24]}>
        <Col xs={24} md={8}>
          <Card className="profile-card user-info-card">
            <div className="profile-header">
              <Avatar size={100} icon={<UserOutlined />} src={user.avatar} />
              <Title level={2}>{user.username}</Title>
              <Text type="secondary">{user.email}</Text>
            </div>
            <Divider />
            <div className="info-item">
              <BookOutlined className="info-icon" />
              <div>
                <Text type="secondary">Grade</Text>
                <div>{user.grade}th</div>
              </div>
            </div>
            <div className="info-item">
              <TranslationOutlined className="info-icon" />
              <div>
                <Text type="secondary">Language</Text>
                <div>{user.language.toUpperCase()}</div>
              </div>
            </div>
            <div className="info-item">
              <GlobalOutlined className="info-icon" />
              <div>
                <Text type="secondary">Region</Text>
                <div>{user.region ? user.region.toUpperCase() : "Not Selected"}</div>
              </div>
            </div>
            <div className="info-item">
              <FlagOutlined className="info-icon" />
              <div>
                <Text type="secondary">Learning Goal</Text>
                <div>{user.learning_goal || "Not Selected"}</div>
              </div>
            </div>
            <Divider />
            <Button type="primary" icon={<EditOutlined />} onClick={handleEditProfile} block>
              Edit Profile
            </Button>
          </Card>
        </Col>
        <Col xs={24} md={16}>
          <Card className="profile-card">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Overview" key="1">
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Statistic 
                      title="Questions Answered" 
                      value={stats.totalQuestions} 
                      prefix={<TrophyOutlined />} 
                    />
                  </Col>
                  <Col span={8}>
                    <Statistic 
                      title="Streak" 
                      value={stats.streak} 
                      suffix="days" 
                    />
                  </Col>
                  <Col span={8}>
                    <Statistic 
                      title="Accuracy" 
                      value={stats.accuracy.toFixed(2)} 
                      suffix="%" 
                    />
                    <Tooltip title="Your performance percentile based on accuracy and questions answered">
                      <Statistic 
                        title="Percentile" 
                        value={stats.percentile.toFixed(1)} 
                        suffix="th"
                        prefix={<PercentageOutlined />}
                        valueStyle={{ fontSize: '14px' }}
                      />
                    </Tooltip>
                  </Col>
                </Row>
                <Divider />
                <Title level={4}>Account Information</Title>
                <div className="info-item">
                  <IdcardOutlined className="info-icon" />
                  <div>
                    <Text type="secondary">Subscription Tier</Text>
                    <div>{user.subscription_tier}</div>
                  </div>
                </div>
                <div className="info-item">
                  <CalendarOutlined className="info-icon" />
                  <div>
                    <Text type="secondary">Member Since</Text>
                    <div>{user.created_at || "Not Available"}</div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Progress" key="2">
                <p>Your learning progress will be displayed here.</p>
              </TabPane>
              <TabPane tab="Achievements" key="3">
                <p>Your achievements and badges will be displayed here.</p>
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>

      <UpdateUserModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
      />
    </div>
  );
}

export default ProfilePage;