import React, { useState } from 'react';
import { Row, Col, Card, Tag, Input, List, Typography, Avatar, Modal, Button } from 'antd';
import { SearchOutlined, TeamOutlined, DollarOutlined, RocketOutlined, LinkedinOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const { Title, Paragraph } = Typography;

const PageWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const JobCard = styled(Card)`
  margin-bottom: 24px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  }
`;

const ApplyButton = styled(Button)`
  margin-top: 16px;
`;

const CareerPage = () => {
  const [selectedJob, setSelectedJob] = useState(null);

  const jobs = [
    {
      title: "Software Engineer",
      description: "We're seeking a talented Senior Software Engineer to lead development efforts on our cutting-edge platform. You'll work with a diverse team of engineers to solve complex problems and create innovative solutions.",
      requirements: [
        "1+ years of experience in software development",
        "Proficiency in React, Node.js, and TypeScript",
        "Experience with cloud technologies (AWS, Azure, or GCP)",
        "Strong problem-solving and communication skills",
        "Bachelor's degree in Computer Science or related field"
      ],
      benefits: [
        "Flexible work hours and remote work options",
        "stock options",
        
      ],
      category: "Engineering",
      location: "Remote",
      type: "Part-time",
      linkedInUrl: "https://www.linkedin.com/jobs/view/software-engineer-at-coursect",
    },
    {
      title: "Digital Marketing Manager",
      description: "Join our marketing team to lead our digital marketing efforts. You'll develop and execute strategies to grow our brand presence, increase customer engagement, and drive conversions across various digital channels.",
      requirements: [
        "1+ years of experience in digital marketing",
        "Proficiency in SEO, SEM, and social media marketing",
        "Experience with marketing analytics tools",
        "Strong analytical and creative skills",
        "Bachelor's degree in Marketing or related field"
      ],
      benefits: [
        "Flexible work schedule",
        "Ongoing training and career development opportunities",
        "performance bonuses",
      ],
      category: "Marketing",
      location: "Remote / PA",
      type: "Part-time",
      linkedInUrl: "https://www.linkedin.com/jobs/view/digital-marketing-manager-at-coursect",
    },
    {
      title: "Business Development Director",
      description: "We're looking for a strategic Business Development Director to identify and pursue new growth opportunities. You'll play a crucial role in expanding our market presence and forging valuable partnerships.",
      requirements: [
        "1+ years of experience in business development or sales",
        "Proven track record of closing high-value deals",
        "Strong network in the tech industry",
        "Excellent negotiation and presentation skills",
      ],
      benefits: [
        "Stock options",
        "Executive health benefits package",
        "Travel opportunities",
        "Leadership development programs"
      ],
      category: "Business",
      location: "Remote / PA",
      type: "Part-time",
      linkedInUrl: "https://www.linkedin.com/jobs/view/business-development-director-at-coursect",
    },
  ];

  const handleJobClick = (job) => {
    setSelectedJob(job);
  };

  const handleModalClose = () => {
    setSelectedJob(null);
  };

  const handleApply = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <PageWrapper>
      <Title level={1}>Join Our Team</Title>
      <Paragraph>
        At Coursect, we're revolutionizing education through innovative technology. Join us in shaping the future of learning!
      </Paragraph>

      <Row gutter={[24, 24]}>
        <Col xs={24} lg={16}>
          <List
            dataSource={jobs}
            renderItem={job => (
              <JobCard hoverable onClick={() => handleJobClick(job)}>
                <Title level={4}>{job.title}</Title>
                <Paragraph ellipsis={{ rows: 2 }}>{job.description}</Paragraph>
                <div>
                  <Tag color="blue">{job.category}</Tag>
                  <Tag color="green">{job.location}</Tag>
                  <Tag color="orange">{job.type}</Tag>
                </div>
              </JobCard>
            )}
          />
        </Col>

        <Col xs={24} lg={8}>
          <Card className="sidebar-section">
            <Title level={3}>Search Jobs</Title>
            <Input prefix={<SearchOutlined />} placeholder="Search job openings" />
          </Card>

          <Card className="sidebar-section">
            <Title level={3}><TeamOutlined /> Why Join Us?</Title>
            <List
              dataSource={[
                "Innovative and impactful work",
                "Collaborative team environment",
                "Continuous learning and growth",
                "Competitive",
                "Work-life balance"
              ]}
              renderItem={item => (
                <List.Item>
                  <RocketOutlined style={{ marginRight: 8 }} /> {item}
                </List.Item>
              )}
            />
          </Card>

          <Card className="sidebar-section">
            <Title level={3}><DollarOutlined /> Benefits</Title>
            <List
              dataSource={[
                "Flexible work arrangements",
                "Professional development budget",
                "Stock options",
                "performance bonuses",
                "Innovative and impactful work",
              ]}
              renderItem={item => (
                <List.Item>
                  {item}
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>

      <Modal
        title={selectedJob?.title}
        visible={!!selectedJob}
        onCancel={handleModalClose}
        footer={null}
        width="90%"
      >
        <Paragraph>{selectedJob?.description}</Paragraph>
        <Title level={4}>Requirements:</Title>
        <List
          dataSource={selectedJob?.requirements}
          renderItem={item => <List.Item>{item}</List.Item>}
        />
        <Title level={4}>Benefits:</Title>
        <List
          dataSource={selectedJob?.benefits}
          renderItem={item => <List.Item>{item}</List.Item>}
        />
        <ApplyButton 
          type="primary" 
          size="large" 
          icon={<LinkedinOutlined />}
          onClick={() => handleApply(selectedJob?.linkedInUrl)}
        >
          Apply on LinkedIn
        </ApplyButton>
      </Modal>
    </PageWrapper>
  );
};

export default CareerPage;