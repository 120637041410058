import React from 'react';
import Header from '../components/landing/Header';
import Footer from '../components/landing/Footer'; // Add this import
import './MainLayout.css';

const MainLayout = ({ children }) => {
  return (
    <div className="main-layout">
      <Header />
      <main className="main-content">
        {children}
      </main>
      <Footer /> {/* Add the Footer component here */}
    </div>
  );
};

export default MainLayout;