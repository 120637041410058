import React, { useState } from 'react';
import { Row, Col, Card, Tag, Input, List, Typography, Avatar, Modal } from 'antd';
import { SearchOutlined, ClockCircleOutlined, UserOutlined, FireOutlined } from '@ant-design/icons';
import './BlogPage.css';
const { Title, Paragraph } = Typography;

const BlogPage = () => {
  const [selectedPost, setSelectedPost] = useState(null);

  const featuredPost = {
    title: "The Future of AI in Education: A Game-Changer for Learning",
    excerpt: "Artificial Intelligence is set to revolutionize education. From personalized learning paths to intelligent tutoring systems, AI is reshaping how we teach and learn. Discover the exciting possibilities and potential challenges as we dive into the future of education powered by AI.",
    image: "https://images.unsplash.com/photo-1620712943543-bcc4688e7485?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
    author: "Warren Noubi",
    avatar: "https://th.bing.com/th/id/OIP.x_xjD3EgFtuBYO-Holx2gAAAAA?rs=1&pid=ImgDetMain",
    date: "september 1, 2024",
    category: "EdTech",
    fullContent: `
      <h1>The Future of AI in Education: A Game-Changer for Learning</h1>
      <p>Artificial Intelligence (AI) is poised to revolutionize the education sector, transforming how we teach and learn in ways we could only imagine a few years ago. As we stand on the brink of this educational revolution, it's crucial to understand the potential impacts, benefits, and challenges that AI brings to the table.</p>
      <h2>Personalized Learning Paths</h2>
      <p>One of the most significant advantages of AI in education is its ability to create personalized learning experiences. AI algorithms can analyze a student's performance, learning style, and pace to tailor educational content specifically for them. This level of customization ensures that each student receives the most effective learning experience possible, addressing their unique strengths and weaknesses.</p>
      <h2>Intelligent Tutoring Systems</h2>
      <p>AI-powered tutoring systems are becoming increasingly sophisticated, capable of providing instant feedback and guidance to students. These systems can identify areas where a student is struggling and offer targeted assistance, much like a human tutor would. As these systems continue to evolve, they have the potential to provide high-quality, one-on-one tutoring at scale, making personalized education more accessible than ever before.</p>
      <h2>Automated Grading and Feedback</h2>
      <p>AI can significantly reduce the time educators spend on administrative tasks like grading. Advanced natural language processing allows AI to grade essays and provide detailed feedback, freeing up teachers to focus on more high-value activities like lesson planning and direct student interaction.</p>
      <h2>Challenges and Considerations</h2>
      <p>While the potential of AI in education is enormous, it's important to address potential challenges. Issues such as data privacy, the digital divide, and the need for human interaction in education must be carefully considered as we integrate AI into our educational systems.</p>
      <h2>Conclusion</h2>
      <p>As we look to the future, it's clear that AI will play a pivotal role in shaping education. By embracing these technologies thoughtfully and responsibly, we have the opportunity to create more effective, accessible, and personalized learning experiences for students around the world.</p>
    `
  };

  const blogPosts = [
    {
      title: "Mastering the Art of Online Learning: 5 Essential Strategies",
      excerpt: "Online learning has become the new norm. Whether you're a student or a professional looking to upskill, these five strategies will help you maximize your online learning experience and achieve your educational goals.",
      image: "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
      author: "Jack Zhang",
      avatar: "https://th.bing.com/th/id/OIP.mM1b_rC3LcI8Xi9dgwTOtAHaHa?rs=1&pid=ImgDetMain",
      date: "May 10, 2023",
      category: "Study Tips",
      fullContent: `
        <h1>Mastering the Art of Online Learning: 5 Essential Strategies</h1>
        <p>Online learning has become an integral part of education in the digital age. Whether you're a student adapting to remote classes or a professional looking to upskill, mastering the art of online learning is crucial for success. Here are five essential strategies to help you make the most of your online learning experience:</p>
        <h2>1. Create a Dedicated Learning Space</h2>
        <p>Set up a specific area in your home dedicated to learning. This helps create a boundary between your personal life and your studies, making it easier to focus when it's time to learn.</p>
        <h2>2. Develop a Consistent Schedule</h2>
        <p>Establish a regular study routine. Consistency is key in online learning. Set specific times for attending online classes, studying, and completing assignments.</p>
        <h2>3. Active Participation is Key</h2>
        <p>Engage actively in online discussions, ask questions, and participate in virtual group projects. Active participation enhances your understanding and helps you stay motivated.</p>
        <h2>4. Utilize Online Resources</h2>
        <p>Take advantage of the wealth of online resources available. This includes your course materials, online libraries, educational videos, and collaborative tools.</p>
        <h2>5. Practice Self-Care and Time Management</h2>
        <p>Balance your studies with self-care. Take regular breaks, exercise, and maintain a healthy sleep schedule. Good time management and self-care are crucial for sustainable online learning.</p>
        <p>By implementing these strategies, you can enhance your online learning experience and achieve your educational goals more effectively. Remember, online learning is a skill that improves with practice. Stay patient and persistent, and you'll master the art of learning in the digital age.</p>
      `
    },
    {
      title: "The Rise of Microlearning: Big Impact in Small Packages",
      excerpt: "Microlearning is taking the education world by storm. This bite-sized learning approach is proving to be highly effective in today's fast-paced world. Learn why it's gaining popularity and how you can leverage it for your own learning journey.",
      image: "https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
      author: "Warren Noubi",
      avatar: "https://th.bing.com/th/id/OIP.x_xjD3EgFtuBYO-Holx2gAAAAA?rs=1&pid=ImgDetMain",
      date: "May 5, 2023",
      category: "Learning Trends",
      fullContent: `
        <h1>The Rise of Microlearning: Big Impact in Small Packages</h1>
        <p>In the rapidly evolving world of education, one trend that has emerged as a game-changer is microlearning. This approach to learning involves breaking down complex topics into smaller, manageable chunks, each focusing on a specific skill or concept.</p>
        <h2>The Benefits of Microlearning</h2>
        <p>Microlearning offers several advantages over traditional learning methods:</p>
        <ul>
          <li><strong>Increased Engagement:</strong> Short, focused lessons keep learners engaged and motivated.</li>
          <li><strong>Flexibility:</strong> Microlearning allows learners to fit learning into their busy schedules.</li>
          <li><strong>Improved Retention:</strong> Smaller chunks of information are easier to remember and apply.</li>
          <li><strong>Cost-Effective:</strong> Microlearning can be more cost-effective than traditional training programs.</li>
        </ul>
        <h2>Leveraging Microlearning for Your Learning Journey</h2>
        <p>Here are some tips for leveraging microlearning to enhance your learning experience:</p>
        <ol>
          <li>Identify your learning goals and break them down into smaller objectives.</li>
          <li>Look for microlearning opportunities in your daily life, such as watching short videos or reading short articles.</li>
          <li>Utilize online platforms and resources that offer microlearning courses.</li>
          <li>Create a schedule for your microlearning sessions and stick to it.</li>
          <li>Reflect on your learning and apply what you've learned in real-world situations.</li>
        </ol>
        <h2>Conclusion</h2>
        <p>Microlearning is a powerful tool that can revolutionize the way we learn. By embracing this approach, we can increase engagement, improve retention, and make learning more flexible and accessible. So, why wait? Start incorporating microlearning into your learning journey today!</p>
      `
    },
    {
      title: "Gamification in Education: Leveling Up Student Engagement",
      excerpt: "Gamification is more than just adding fun to learning—it's about creating immersive experiences that boost engagement and retention. Discover how game elements are being used to transform education and why it's proving to be so effective.",
      image: "https://images.unsplash.com/photo-1511377107391-116a9d5d20b5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
      author: "Jack Zhang",
      avatar: "https://th.bing.com/th/id/OIP.mM1b_rC3LcI8Xi9dgwTOtAHaHa?rs=1&pid=ImgDetMain",
      date: "April 30, 2023",
      category: "EdTech",
      fullContent: `
        <h1>Gamification in Education: Leveling Up Student Engagement</h1>
        <p>Gamification is a powerful tool that can significantly enhance the learning experience for students. By incorporating game elements into educational activities, educators can create immersive, engaging, and effective learning environments.</p>
        <h2>The Power of Gamification</h2>
        <p>Gamification leverages the principles of game design to make learning more enjoyable and motivating. It can:</p>
        <ul>
          <li><strong>Increase Engagement:</strong> Gamification adds an element of fun and competition, making learning more enjoyable and engaging.</li>
          <li><strong>Boost Motivation:</strong> Gamification can motivate students by turning learning into a game, with rewards and challenges.</li>
          <li><strong>Improve Retention:</strong> Gamification can help students retain information better through interactive and memorable learning experiences.</li>
          <li><strong>Foster Collaboration:</strong> Gamification can promote collaboration and teamwork among students.</li>
        </ul>
        <h2>Gamification in Action</h2>
        <p>Here are some examples of how gamification is being used in education:</p>
        <ol>
          <li><strong>Educational Games:</strong> Interactive educational games can teach complex concepts in a fun and engaging way.</li>
          <li><strong>Learning Platforms:</strong> Many online learning platforms incorporate gamification features, such as badges, leaderboards, and rewards.</li>
          <li><strong>Virtual Classrooms:</strong> Gamification can be used to create interactive virtual classrooms, with features like virtual reality and augmented reality.</li>
          <li><strong>Serious Games:</strong> Serious games can be used to simulate real-world scenarios, promoting problem-solving and critical thinking skills.</li>
        </ol>
        <h2>Conclusion</h2>
        <p>Gamification is a powerful tool that can transform education by increasing engagement, motivation, retention, and collaboration. By embracing gamification, educators can create more immersive, enjoyable, and effective learning experiences for students.</p>
      `
    },
  ];

  const categories = ["EdTech", "Study Tips", "Learning Trends", "Student Success", "Industry Insights"];

  const popularPosts = [
    "10 Must-Have Skills for the Digital Age",
    "How to Stay Motivated in Online Courses",
    "The Impact of VR on Distance Learning",
    "Collaborative Learning in Virtual Classrooms"
  ];

  const handlePostClick = (post) => {
    setSelectedPost(post);
  };

  const handleModalClose = () => {
    setSelectedPost(null);
  };

  return (
    <div className="blog-page">
      <Title level={1} className="page-title">Coursect Insights</Title>
      <Paragraph className="page-description">
        Exploring the future of education, one byte at a time
      </Paragraph>

      <Row gutter={[24, 24]}>
        <Col xs={24} lg={16}>
          <Card 
            className="featured-post"
            cover={<img alt={featuredPost.title} src={featuredPost.image} />}
            onClick={() => handlePostClick(featuredPost)}
          >
            <Tag color="blue">{featuredPost.category}</Tag>
            <Title level={2}>{featuredPost.title}</Title>
            <Paragraph>{featuredPost.excerpt}</Paragraph>
            <div className="post-meta">
              <Avatar src={featuredPost.avatar} />
              <span>{featuredPost.author}</span>
              <ClockCircleOutlined /> {featuredPost.date}
            </div>
          </Card>

          <List
            itemLayout="vertical"
            size="large"
            dataSource={blogPosts}
            renderItem={post => (
              <Card className="blog-post-card" hoverable onClick={() => handlePostClick(post)}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={8}>
                    <img alt={post.title} src={post.image} className="post-image" />
                  </Col>
                  <Col xs={24} sm={16}>
                    <Tag color="blue">{post.category}</Tag>
                    <Title level={3}>{post.title}</Title>
                    <Paragraph ellipsis={{ rows: 3 }}>{post.excerpt}</Paragraph>
                    <div className="post-meta">
                      <Avatar src={post.avatar} />
                      <span>{post.author}</span>
                      <ClockCircleOutlined /> {post.date}
                    </div>
                  </Col>
                </Row>
              </Card>
            )}
          />
        </Col>

        <Col xs={24} lg={8}>
          <Card className="sidebar-section">
            <Title level={3}>Search</Title>
            <Input prefix={<SearchOutlined />} placeholder="Search blog posts" />
          </Card>

          <Card className="sidebar-section">
            <Title level={3}>Categories</Title>
            <List
              dataSource={categories}
              renderItem={item => (
                <List.Item>
                  <Tag color="blue">{item}</Tag>
                </List.Item>
              )}
            />
          </Card>

          <Card className="sidebar-section">
            <Title level={3}><FireOutlined /> Trending Posts</Title>
            <List
              dataSource={popularPosts}
              renderItem={item => (
                <List.Item>
                  <a href="#">{item}</a>
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>

      <Modal
        title={selectedPost?.title}
        visible={!!selectedPost}
        onCancel={handleModalClose}
        footer={null}
        width="90%"
        className="blog-post-modal"
      >
        <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: selectedPost?.fullContent }} />
        <div className="post-meta">
          <Avatar src={selectedPost?.avatar} />
          <span>{selectedPost?.author}</span>
          <ClockCircleOutlined /> {selectedPost?.date}
        </div>
      </Modal>
    </div>
  );
};

export default BlogPage;