import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ImageIcon from '@mui/icons-material/Image';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    padding: theme.spacing(3),
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
    animation: `${fadeIn} 0.5s ease-out`,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 28,
  padding: theme.spacing(1, 4),
  transition: 'all 0.3s ease',
  fontWeight: 600,
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  padding: theme.spacing(2),
  display: 'inline-flex',
  marginBottom: theme.spacing(2),
}));

const UploadPopUp = ({ open, onClose }) => {
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="upgrade-dialog-title"
      aria-describedby="upgrade-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="upgrade-dialog-title" sx={{ textAlign: 'center', pb: 0 }}>
        <IconWrapper>
          <ImageIcon fontSize="large" sx={{ color: 'white' }} />
        </IconWrapper>
        <Typography variant="h4" component="div" fontWeight="bold" gutterBottom>
          Unlock Image Chat
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography id="upgrade-dialog-description" variant="body1" paragraph align="center">
          Upgrade your account to access our powerful image chat feature and take your learning to the next level.
        </Typography>
        <List>
          {['Upload and analyze images', 'Get AI-powered insights', 'Enhance learning with visual aids'].map((text, index) => (
            <ListItem key={index} sx={{ py: 0.5 }}>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'column', alignItems: 'stretch', pt: 2 }}>
        <StyledButton
          onClick={() => {
            console.log('Upgrade initiated');
            onClose();
          }}
          color="primary"
          variant="contained"
          fullWidth
          size="large"
          sx={{ mb: 1 }}
        >
          Upgrade Now
        </StyledButton>
        <Button onClick={onClose} color="primary" sx={{ textTransform: 'none' }}>
          Maybe Later
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default UploadPopUp;
