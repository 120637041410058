import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editUser } from '../../store/user';
import { setUser } from '../../store/session';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Typography,
  IconButton,
  MenuItem,
  Divider,
} from '@mui/material';
import {
  Person as PersonIcon,
  School as SchoolIcon,
  Translate as TranslateIcon,
  Public as PublicIcon,
  Lock as LockIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

function UpdateUserModal({ open, onClose }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const [formData, setFormData] = useState({
    username: user?.username || '',
    grade: user?.grade || '',
    language: user?.language || '',
    region: user?.region || '',
    newPassword: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.username) newErrors.username = 'Username is required';
    if (!formData.grade) newErrors.grade = 'Grade is required';
    if (!formData.language) newErrors.language = 'Language is required';
    if (!formData.region) newErrors.region = 'Region is required';
    if (formData.newPassword !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const payload = {
      id: user.id,
      username: formData.username,
      grade: formData.grade,
      language: formData.language,
      region: formData.region,
    };

    if (formData.newPassword) {
      payload.new_password = formData.newPassword;
    }

    try {
      const updatedUser = await dispatch(editUser(payload));
      if (updatedUser) {
        dispatch(setUser(updatedUser));
        onClose();
      }
    } catch (error) {
      console.error('Error updating user:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Edit Profile</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Email: {user?.email}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                error={!!errors.username}
                helperText={errors.username}
                InputProps={{
                  startAdornment: <PersonIcon color="action" />,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                label="Grade"
                name="grade"
                value={formData.grade}
                onChange={handleChange}
                error={!!errors.grade}
                helperText={errors.grade}
                InputProps={{
                  startAdornment: <SchoolIcon color="action" />,
                }}
              >
                {['8', '9', '10', '11', '12'].map((grade) => (
                  <MenuItem key={grade} value={grade}>
                    {grade}th Grade
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                label="Language"
                name="language"
                value={formData.language}
                onChange={handleChange}
                error={!!errors.language}
                helperText={errors.language}
                InputProps={{
                  startAdornment: <TranslateIcon color="action" />,
                }}
              >
                {['english', 'spanish', 'french', 'chinese'].map((lang) => (
                  <MenuItem key={lang} value={lang}>
                    {lang.charAt(0).toUpperCase() + lang.slice(1)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Region"
                name="region"
                value={formData.region}
                onChange={handleChange}
                error={!!errors.region}
                helperText={errors.region}
                InputProps={{
                  startAdornment: <PublicIcon color="action" />,
                }}
              >
                {['north-america', 'europe', 'asia', 'africa'].map((region) => (
                  <MenuItem key={region} value={region}>
                    {region.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                label="New Password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
                InputProps={{
                  startAdornment: <LockIcon color="action" />,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                label="Confirm Password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                InputProps={{
                  startAdornment: <LockIcon color="action" />,
                }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateUserModal;
