import React from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Typography, Collapse, Card } from "antd";
import { 
  RocketOutlined, 
  InteractionOutlined, 
  BookOutlined, 
  AppstoreAddOutlined,
  ReadOutlined,
  GlobalOutlined
} from "@ant-design/icons";
import "./FeaturesSection.css";

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

function FeaturesSection({ featuresRef }) {
  const history = useHistory();

  const features = [
    {
      icon: <RocketOutlined />,
      title: "Personalized Question Generation",
      content: "Leveraging advanced Machine learning algorithms, the platform generates practice questions based on student level and chosen subjects, guaranteeing targeted and relevant learning experiences."
    },
    {
      icon: <InteractionOutlined />,
      title: "Interactive Learning",
      content: "Students actively engage with generated questions, solve them, and receive instant, detailed feedback. This method encourages participation and fosters continuous improvement."
    },
    {
      icon: <BookOutlined />,
      title: "In-depth Explanations",
      content: "The platform provides thorough explanations along with correct answers, helping students grasp underlying concepts and problem-solving strategies."
    },
    {
      icon: <AppstoreAddOutlined />,
      title: "Expanded Content",
      content: "The curriculum progressively encompasses diverse subjects, enabling access to comprehensive learning resources across various academic domains."
    },
    {
      icon: <ReadOutlined />,
      title: "Study Materials and Resources",
      content: "In addition to practice questions, the platform offers a wealth of study materials, enhancing students' understanding and knowledge retention."
    },
    {
      icon: <GlobalOutlined />,
      title: "Multilingual Accessibility",
      content: "Designed for a global audience, the platform supports multiple languages, making quality education accessible to students worldwide."
    }
  ];

  return (
    <section 
      ref={featuresRef} 
      className="ms-features-section"
      onMouseOver={() => history.push("/#features")}
    >
      <div className="ms-container">
        <Row gutter={[48, 48]} align="middle">
          <Col xs={24} lg={12}>
            <div className="ms-features-content">
              <Title level={2} className="ms-section-title">
                Empowering <span className="ms-highlight">Learning Journeys</span>
              </Title>
              <Paragraph className="ms-section-description">
                Coursect, an AI Tutoring Platform, is transforming education by shattering barriers for students globally. 
                With its commitment to providing high-quality, accessible learning experiences, Coursect empowers academic excellence
                and champions an inclusive future for education.
              </Paragraph>
              <Collapse className="ms-features-collapse">
                {features.map((feature, index) => (
                  <Panel 
                    header={
                      <span className="ms-feature-title">
                        {feature.icon} {feature.title}
                      </span>
                    } 
                    key={index + 1}
                  >
                    <Paragraph>{feature.content}</Paragraph>
                  </Panel>
                ))}
              </Collapse>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="ms-features-image">
              <img src="assets/images/default-color/lms-software-img-2.png" alt="Learning Management System" />
            </div>
          </Col>
        </Row>
      </div>
      <div className="ms-background-shape"></div>
    </section>
  );
}

export default FeaturesSection;