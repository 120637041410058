import React, { useRef } from "react";
import Header from "../../components/landing/Header";
import HeroSection from "../../components/landing/HeroSection";
import ServicesSection from "../../components/landing/ServicesSection";
import CTASection from "../../components/landing/CTASection";
import FeaturesSection from "../../components/landing/FeaturesSection";
import MissionSection from "../../components/landing/MissionSection";
import PricingSection from "../PricingPage";
import SubscribeSection from "../../components/landing/SubscribeSection";

function LandingPage() {
  const homeRef = useRef(null);
  const servicesRef = useRef(null);
  const ctaRef = useRef(null);
  const featuresRef = useRef(null);
  const contactUsRef = useRef(null);
  const aboutUsRef = useRef(null);
  const pricingRef = useRef(null);

  return (
    <>
      <Header
        homeRef={homeRef}
        servicesRef={servicesRef}
        ctaRef={ctaRef}
        featuresRef={featuresRef}
        aboutUsRef={aboutUsRef}
        pricingRef={pricingRef}
        contactUsRef={contactUsRef}
        
      />
      <div id="main-wrapper" className="page-wrapper">
        <HeroSection homeRef={homeRef} />
        <ServicesSection servicesRef={servicesRef} />
        <CTASection ctaRef={ctaRef} contactUsRef={contactUsRef} />
        <FeaturesSection featuresRef={featuresRef} />
        <MissionSection aboutUsRef={aboutUsRef} />
        
        <SubscribeSection contactUsRef={contactUsRef} />
      </div>
    </>
  );
}

export default LandingPage;
