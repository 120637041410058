import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Row, Col, Card } from "antd";
import { RocketOutlined, EyeOutlined } from "@ant-design/icons";
import "./MissionSection.css";

const { Title, Paragraph } = Typography;

function MissionSection({ aboutUsRef }) {
  const history = useHistory();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
        }
      });
    }, { threshold: 0.1 });

    document.querySelectorAll('.ms-mission-card').forEach((card) => {
      observer.observe(card);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section 
      ref={aboutUsRef} 
      className="ms-mission-section"
      onMouseOver={() => history.push("/#aboutUs")}
    >
      <div className="ms-container">
        <Row justify="center" gutter={[0, 48]}>
          <Col xs={24} lg={18}>
            <Title level={2} className="ms-section-title">
              Our <span className="ms-highlight">Mission</span> and <span className="ms-highlight">Vision</span>
            </Title>
            <Paragraph className="ms-section-description">
              Innovative education solution leveraging cutting-edge technology for universal access to high-quality, 
              tailored learning experiences, unlocking individual potential across diverse backgrounds.
            </Paragraph>
          </Col>
        </Row>

        <Row gutter={[32, 32]} className="ms-mission-row">
          <Col xs={24} md={12}>
            <Card className="ms-mission-card">
              <RocketOutlined className="ms-card-icon" />
              <Title level={3}>Mission and Impact</Title>
              <Paragraph>
                Empower learners globally by providing accessible, personalized, and innovative educational solutions 
                that transcend barriers, fostering academic excellence and nurturing a passion for lifelong learning.
              </Paragraph>
            </Card>
          </Col>
          <Col xs={24} md={12}>
            <Card className="ms-mission-card">
              <EyeOutlined className="ms-card-icon" />
              <Title level={3}>Vision</Title>
              <Paragraph>
                Revolutionizing education with technology for universal access to high-quality, tailored learning, 
                unlocking the full potential of every individual, irrespective of background.
              </Paragraph>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="ms-background-shape"></div>
    </section>
  );
}

export default MissionSection;