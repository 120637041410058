// Action Types + Action Creators
const ADD_PERFORMANCE = 'ADD_PERFORMANCE';
export const addPerformance = (performance) => ({
  type: ADD_PERFORMANCE,
  payload: performance
});

const DELETE_PERFORMANCE = 'DELETE_PERFORMANCE';
export const deletePerformance = (id) => ({
  type: DELETE_PERFORMANCE,
  payload: id
});

const GET_PERFORMANCE = 'GET_PERFORMANCE';
export const getPerformance = (performance) => ({
  type: GET_PERFORMANCE,
  payload: performance
});

// Add this to your existing actions
export const GET_USER_RANK = 'performance/GET_USER_RANK';

export const getUserRank = (rank) => ({
  type: GET_USER_RANK,
  payload: rank,
});

export const getUserRankThunk = () => async (dispatch) => {
  const response = await fetch('/api/users/rank');
  if (response.ok) {
    const data = await response.json();
    dispatch(getUserRank(data.rank));
  }
};

// Thunks
export const getPerformanceThunk = () => async (dispatch) => {
  try {
    const response = await fetch(`/api/performances/`);
    if (!response.ok) {
      throw response;
    }
    const performance = await response.json();
    dispatch(getPerformance(performance || [])); // Ensure payload is always an array
  } catch (error) {
    console.error("Error:", error);
  }
};

export const addPerformanceThunk = (performance) => (dispatch) => {
  // Here you can perform async operations, like API calls, then dispatch the action
  dispatch(addPerformance(performance));
};

export const deletePerformanceThunk = (id) => (dispatch) => {
  // Here you can perform async operations, like API calls, then dispatch the action
  dispatch(deletePerformance(id));
};

// Update your reducer
const initialState = {
  performances: [],
  userRank: null,
};

const performanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PERFORMANCE:
      return action.payload;
    case ADD_PERFORMANCE:
      return [...state, action.payload];
    case DELETE_PERFORMANCE:
      return state.filter(performance => performance.id !== action.payload);
    case GET_USER_RANK:
      return { ...state, userRank: action.payload };
    default:
      return state;
  }
};

export default performanceReducer;
