import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Select, Button, Spin, Alert } from "antd";
import { UserOutlined, MailOutlined, LockOutlined, BookOutlined, GlobalOutlined } from "@ant-design/icons";
import { Redirect, Link, useLocation, useHistory } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { signUp, login } from "../../store/session";
import "./Signup.css";

const { Option } = Select;

const Signup = () => {
  const sessionUser = useSelector((state) => state.session.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  if (sessionUser) {
    if (location?.state?.isCreateSubscription) {
      fetch("/api/stripe/config")
        .then((result) => result.json())
        .then(async (data) => {
          const stripe = await loadStripe(data.publicKey);
          fetch(`/api/stripe/create-checkout-session/${sessionUser.id}/${location?.state?.subscription}`)
            .then((result) => result.json())
            .then((data) => {
              console.log(data);
              return stripe.redirectToCheckout({ sessionId: data.sessionId });
            })
            .then((res) => { console.log(res); });
        });
    } else {
      return <Redirect to="/home" />;
    }
  }

  const onFinish = async (values) => {
    console.log("Received from form: ", values);
    if (values.password === values.password2) {
      setIsLoading(true);
      const data = await dispatch(
        signUp(
          values.username,
          values.email,
          values.password,
          values.grade,
          values.language,
          values.region || null,
          null
        )
      );
      setIsLoading(false);

      if (!data) {
        await dispatch(login(values.email, values.password));
      } else {
        setErrors(data);
      }
    } else {
      setErrors(["Confirm Password field must be the same as the Password field"]);
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-box">
        <img src="/path-to-your-logo.png" alt="Coursect Logo" className="logo" />
        <h1>Create Your Account</h1>
        <Form name="signup" onFinish={onFinish} layout="vertical">
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your Name!" }]}
          >
            <Input prefix={<UserOutlined />} placeholder="First & Last name" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email address!", type: "email" }]}
          >
            <Input prefix={<MailOutlined />} placeholder="Email Address" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="password2"
            rules={[{ required: true, message: "Please confirm your password!" }]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Confirm Your Password" />
          </Form.Item>
          <Form.Item
            name="grade"
            rules={[{ required: true, message: "Please select your grade!" }]}
          >
            <Select placeholder="Choose Current Grade">
              <Option value="8">8th Grade</Option>
              <Option value="9">9th Grade</Option>
              <Option value="10">10th Grade</Option>
              <Option value="11">11th Grade</Option>
              <Option value="12">12th Grade</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="language"
            rules={[{ required: true, message: "Please select your language!" }]}
          >
            <Select placeholder="Choose Language">
              <Option value="english">English</Option>
              <Option value="spanish">Spanish</Option>
              <Option value="french">French</Option>
              <Option value="chinese">Chinese</Option>
              <Option value="japanese">Japanese</Option>
              <Option value="german">German</Option>
            </Select>
          </Form.Item>
          <p className="signup-message">
            Create an account to start your free trial today and see how{" "}
            <span className="brand-highlight">Coursect</span> can help you succeed.
          </p>
          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={isLoading}>
              Sign Up
            </Button>
          </Form.Item>
        </Form>
        {errors.length > 0 && (
          <Alert message={errors.join(", ")} type="error" showIcon className="error-alert" />
        )}
        <div className="signup-footer">
          Already have an account?{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (location?.state?.isCreateSubscription) {
                const subscription = location?.state?.subscription;
                history.push({
                  pathname: "/login",
                  search: `?isCreateSubscription=true&subscription=${subscription}`,
                  state: { isCreateSubscription: true, subscription },
                });
              } else {
                history.push("/login");
              }
            }}
            className="signup-link"
          >
            Sign In
          </a>
          <br />
          Or
          <br />
          <Link to="/" className="signup-link">
            Go back to the landing page
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Signup;