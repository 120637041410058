import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Send,
  Mic,
  VolumeUp,
  VolumeOff,
  Upload,
  Pause,
  EmojiEvents,
  School,
  Psychology,
  AttachMoney,
  Star,
  Help,
} from "@mui/icons-material";
import { 
  Spin, 
  message, 
  Progress, 
  Badge, 
  Avatar, 
  Tooltip, 
  Modal, 
  Button, 
  List,
  Popover,
  Tag
} from "antd";
import styled, { keyframes, createGlobalStyle } from "styled-components";
import Linkify from "./Linkify";
import SttPopUp from "./SttPopUp";
import TtsPopUp from "./TtsPopUp";
import UploadPopUp from "./UploadPopUp";

const GlobalStyle = createGlobalStyle`
  body {
    background: linear-gradient(135deg, #1c92d2 0%, #f2fcfe 100%);
    font-family: 'Roboto', sans-serif;
  }
`;

const GameContainer = styled.div`
  max-width: 100%;
  margin: 10px auto;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (min-width: 768px) {
    max-width: 90%;
    padding: 20px;
    grid-template-columns: 1fr 2fr;
  }

  @media (min-width: 1024px) {
    max-width: 1200px;
    grid-template-columns: 1fr 3fr 1fr;
  }
`;
const PlayerPanel = styled.div`
  background-color: #f0f8ff;
  border-radius: 15px;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  @media (min-width: 768px) {
    padding: 20px;
  }
`;

const GamePanel = styled(PlayerPanel)`
  background-color: #fff1e6;
`;

const PlayerStats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const LevelBadge = styled(Badge)`
  .ant-badge-count {
    background-color: #52c41a;
    color: white;
    font-weight: bold;
  }
`;

const XPProgress = styled(Progress)`
  width: 100%;
`;

const ChatArea = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  height: 500px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  @media (min-width: 768px) {
    padding: 20px;
    height: 600px;
  }
`;

const ChatMessages = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 10px;
`;

const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const Message = styled.div`
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
  max-width: 80%;
  animation: ${floatAnimation} 0.5s ease-out;
  ${props => props.type === "question" ? `
    align-self: flex-end;
    background-color: #1890ff;
    color: white;
    margin-left: auto;
  ` : props.type === "answer" ? `
    align-self: flex-start;
    background-color: #f0f0f0;
    color: black;
  ` : `
    align-self: center;
    background-color: #ffe58f;
    color: #d48806;
    font-weight: bold;
  `}
`;

const ChatInputForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const ChatTextarea = styled.textarea`
  flex-grow: 1;
  width: 100%;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  resize: none;
  height: 40px;
  font-size: 14px;

  @media (min-width: 768px) {
    width: auto;
  }
`;

const ChatButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  padding: 0;

  @media (min-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const FileUploadButton = styled(ChatButton)`
  background-color: #52c41a;
  color: white;
  &:hover {
    background-color: #389e0d;
    color: white;
  }
`;

const StatsItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const AchievementBadge = styled.div`
  background-color: #ffe58f;
  color: #d48806;
  padding: 5px 10px;
  border-radius: 15px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  margin: 5px;
  animation: ${pulseAnimation} 2s infinite;
`;

const PowerUpTag = styled(Tag)`
  animation: ${pulseAnimation} 2s infinite;
  margin-bottom: 10px;
`;

function BackendIntegratedEducationalChatGame() {
  const chatbox = useRef(null);
  const [ttsEnabled, setTtsEnabled] = useState(false);
  const [userQuestion, setUserQuestion] = useState("");
  const [answerLoading, setAnswerLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [error, setError] = useState("");
  const [recognition, setRecognition] = useState(null);
  const [isRecognizing, setIsRecognizing] = useState(false);
  const [gcFileurl, setGcFileurl] = useState("");
  const [filename, setFilename] = useState("");

  const [openTts, setOpenTts] = useState(false);
  const [openStt, setOpenStt] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);

  // Game states
  const [level, setLevel] = useState(1);
  const [xp, setXp] = useState(0);
  const [coins, setCoins] = useState(0);
  const [streak, setStreak] = useState(0);
  const [achievements, setAchievements] = useState([]);
  const [showShop, setShowShop] = useState(false);
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [currentTopic, setCurrentTopic] = useState("General Knowledge");
  const [difficulty, setDifficulty] = useState("Easy");

  const sessionUser = useSelector((state) => state.session.user);
  const subscription_tier = sessionUser?.subscription_tier || "basic";
  const isMobile = window.innerWidth < 200
  useEffect(() => {
    if (chatbox.current) {
      chatbox.current.scrollTop = chatbox.current.scrollHeight;
    }
    setupSpeechRecognition();
  }, [messages]);

  const setupSpeechRecognition = () => {
    if ("webkitSpeechRecognition" in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.lang = "en-US";
      recognition.interimResults = false;
      recognition.maxAlternatives = 1;

      recognition.onresult = (event) => {
        setError("");
        const transcript = event.results[0][0].transcript;
        setUserQuestion(transcript);
      };

      recognition.onerror = (event) => {
        setError("Failed to detect voice");
        console.error("Speech recognition error:", event.error);
      };

      recognition.onend = () => {
        if (isRecognizing) {
          recognition.start();
        }
      };

      setRecognition(recognition);
    }
  };

  const speak = (text) => {
    if (subscription_tier === "basic") {
      setOpenTts(true);
      return;
    }
    if (ttsEnabled) {
      const utterance = new SpeechSynthesisUtterance(text);
      window.speechSynthesis.speak(utterance);
    }
  };

  const askQuestion = async (e) => {
    e.preventDefault();
    if (!userQuestion.trim() && !fileUploaded) return;

    setAnswerLoading(true);
    setMessages(prev => [...prev, { type: "question", text: "You: " + userQuestion }]);

    const data = new FormData();
    data.append("user_question", userQuestion);
    data.append("file_url", gcFileurl);
    data.append("filename", filename);

    try {
      const response = await fetch("/api/openai/image_chat_answer", {
        method: "POST",
        body: data,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const contentType = response.headers.get("content-type");
      const responseText = await response.text();

      let answerText;
      if (contentType && contentType.includes("application/json")) {
        try {
          const answer = JSON.parse(responseText);
          answerText = answer.generated_answer || answer;
        } catch (error) {
          console.error("Error parsing JSON:", error);
          throw new Error("Failed to parse JSON response");
        }
      } else {
        answerText = responseText;
      }

      setMessages(prev => [...prev, { type: "answer", text: "AI Response: " + answerText }]);
      speak(answerText);

      updateGameStats();
    } catch (error) {
      console.error("Error fetching answer:", error);
      setMessages(prev => [...prev, { type: "answer", text: "Error: Failed to get response from the server." }]);
    } finally {
      setAnswerLoading(false);
      setUserQuestion("");
      setFileUploaded(false);
    }
  };

  const handleFileChange = async (event) => {
    if (subscription_tier === "basic") {
      setOpenUpload(true);
      return;
    }
    setAnswerLoading(true);
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append("file", file);
    const response = await fetch("/api/openai/read_pdf", {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      setGcFileurl(data.file_url);
      setFilename(data.filename);
      setMessages(prev => [
        ...prev,
        { type: "question", text: "You: uploaded file - " + data.file_name },
        ...(data?.images ? data.images.map(image => ({ type: "image", text: image })) : []),
      ]);
      setFileUploaded(true);
    }
    setAnswerLoading(false);
  };

  const toggleSpeechRecognition = (e) => {
    if (subscription_tier === "basic") {
      setOpenStt(true);
      return;
    }
    e.preventDefault();
    if (recognition) {
      if (isRecognizing) {
        recognition.stop();
        setIsRecognizing(false);
      } else {
        recognition.start();
        setIsRecognizing(true);
      }
    }
  };

  const handlePaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    for (let item of items) {
      if (item.type.indexOf('image') === 0) {
        const blob = item.getAsFile();
        const reader = new FileReader();
        reader.onload = function(event) {
          setMessages(prev => [...prev, { type: "image", text: event.target.result }]);
        };
        reader.readAsDataURL(blob);
      }
    }
  };

  const updateGameStats = () => {
    const xpGained = Math.floor(Math.random() * 20) + 10;
    const coinsGained = Math.floor(Math.random() * 5) + 1;
    
    setXp(prev => {
      const newXp = prev + xpGained;
      if (newXp >= 100) {
        setLevel(prevLevel => {
          const newLevel = prevLevel + 1;
          message.success(`Level Up! You're now level ${newLevel}`);
          checkAchievements(newLevel, streak + 1, newXp - 100);
          return newLevel;
        });
        return newXp - 100;
      }
      checkAchievements(level, streak + 1, newXp);
      return newXp;
    });

    setCoins(prev => prev + coinsGained);
    setStreak(prev => prev + 1);
    
    message.success(`+${xpGained} XP! +${coinsGained} coins!`);
  };

  const checkAchievements = (currentLevel, currentStreak, currentXp) => {
    const newAchievements = [];

    if (currentLevel === 5 && !achievements.includes("Novice Scholar")) {
      newAchievements.push("Novice Scholar");
    }
    if (currentLevel === 10 && !achievements.includes("Intermediate Learner")) {
      newAchievements.push("Intermediate Learner");
    }
    if (currentStreak === 7 && !achievements.includes("Week Warrior")) {
      newAchievements.push("Week Warrior");
    }
    if (currentXp >= 1000 && !achievements.includes("XP Master")) {
      newAchievements.push("XP Master");
    }

    if (newAchievements.length > 0) {
      setAchievements(prev => [...prev, ...newAchievements]);
      newAchievements.forEach(achievement => {
        setMessages(prev => [...prev, { type: "system", text: `New Achievement Unlocked: ${achievement}!` }]);
      });
    }
  };

  const shopItems = [
    { name: "XP Boost", price: 50,description: "2x XP for your next 5 conversations" },
    { name: "Topic Unlock", price: 100, description: "Unlock a new conversation topic" },
    { name: "Difficulty Increase", price: 200, description: "Increase the difficulty for better rewards" },
  ];

  const leaderboardData = [
    { name: "Player 1", xp: 2500 },
    { name: "Player 2", xp: 2200 },
    { name: "Player 3", xp: 2000 },
    { name: "You", xp: xp },
    { name: "Player 5", xp: 1800 },
  ].sort((a, b) => b.xp - a.xp);

  return (
    <>
      <GlobalStyle />
      <GameContainer>
        {!isMobile && (
          <PlayerPanel>
            <PlayerStats>
              <LevelBadge count={level}>
                <Avatar size={64} icon={<School />} />
              </LevelBadge>
              <StatsItem>
                <Star /> Level: {level}
              </StatsItem>
              <StatsItem>
                <Psychology /> XP: {xp}/100
              </StatsItem>
              <XPProgress percent={xp} status="active" />
              <StatsItem>
                <AttachMoney /> Coins: {coins}
              </StatsItem>
              <StatsItem>
                <EmojiEvents /> Streak: {streak} days
              </StatsItem>
              <Button type="primary" onClick={() => setShowLeaderboard(true)}>Leaderboard</Button>
              <Button type="primary" onClick={() => setShowShop(true)}>Shop</Button>
            </PlayerStats>
          </PlayerPanel>
        )}
  
        <ChatArea>
          {isMobile && (
            <PlayerStats>
              <LevelBadge count={level}>
                <Avatar size={32} icon={<School />} />
              </LevelBadge>
              <StatsItem>
                <Star /> Lvl: {level} | <Psychology /> XP: {xp}/100 | <AttachMoney /> {coins}
              </StatsItem>
              <XPProgress percent={xp} status="active" size="small" />
            </PlayerStats>
          )}
          <h2>Current Topic: {currentTopic} (Difficulty: {difficulty})</h2>
          <ChatMessages ref={chatbox}>
            {messages.map((message, index) => (
              <Message key={index} type={message.type}>
                {message.type === "image" ? (
                  <img src={`data:image/png;base64,${message.text}`} alt="Uploaded content" style={{ maxWidth: '100%', borderRadius: '10px' }} />
                ) : (
                  <Linkify text={message.text} />
                )}
              </Message>
            ))}
          </ChatMessages>
          <Spin spinning={answerLoading}>
            <ChatInputForm onSubmit={askQuestion}>
              <ChatButton
                icon={ttsEnabled ? <VolumeUp /> : <VolumeOff />}
                onClick={() => subscription_tier === "basic" ? setOpenTts(true) : setTtsEnabled(!ttsEnabled)}
              />
              <ChatButton
                icon={isRecognizing ? <Pause /> : <Mic />}
                onClick={toggleSpeechRecognition}
                type={isRecognizing ? "primary" : "default"}
              />
              <Tooltip title="Upload File">
                <FileUploadButton as="label">
                  <Upload />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept="application/pdf, image/*"
                    onChange={handleFileChange}
                  />
                </FileUploadButton>
              </Tooltip>
              <ChatTextarea
                placeholder="Type your question, paste an image, or upload a file..."
                value={userQuestion}
                onChange={(e) => setUserQuestion(e.target.value)}
                onPaste={handlePaste}
              />
              <ChatButton icon={<Send />} htmlType="submit" type="primary" />
              <Popover
                content={
                  <div>
                    <p>Current Topic: {currentTopic}</p>
                    <p>Difficulty: {difficulty}</p>
                    <Button onClick={() => setDifficulty(prev => prev === "Easy" ? "Medium" : prev === "Medium" ? "Hard" : "Easy")}>
                      Change Difficulty
                    </Button>
                  </div>
                }
                title="Game Settings"
              >
                <ChatButton icon={<Help />} />
              </Popover>
            </ChatInputForm>
          </Spin>
        </ChatArea>
  
        {!isMobile && (
          <GamePanel>
            <h2>Achievements</h2>
            {achievements.map((achievement, index) => (
              <AchievementBadge key={index}>
                <Star style={{ marginRight: '5px' }} /> {achievement}
              </AchievementBadge>
            ))}
            <h2>Daily Challenge</h2>
            <p>Complete 5 conversations to earn bonus XP and coins!</p>
          </GamePanel>
        )}
  
        <Modal
          title="Shop"
          visible={showShop}
          onCancel={() => setShowShop(false)}
          footer={null}
        >
          <List
            itemLayout="horizontal"
            dataSource={shopItems}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  title={item.name}
                  description={item.description}
                />
                <Button 
                  onClick={() => {
                    if (coins >= item.price) {
                      setCoins(coins - item.price);
                      message.success(`You bought ${item.name}!`);
                      // Implement the effect of the purchased item
                    } else {
                      message.error("Not enough coins!");
                    }
                  }}
                >
                  Buy for {item.price} coins
                </Button>
              </List.Item>
            )}
          />
        </Modal>
  
        <Modal
          title="Leaderboard"
          visible={showLeaderboard}
          onCancel={() => setShowLeaderboard(false)}
          footer={null}
        >
          <List
            itemLayout="horizontal"
            dataSource={leaderboardData}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar>{index + 1}</Avatar>}
                  title={item.name}
                  description={`XP: ${item.xp}`}
                />
              </List.Item>
            )}
          />
        </Modal>
  
        <TtsPopUp openTts={openTts} setOpenTts={setOpenTts} />
        <SttPopUp openStt={openStt} setOpenStt={setOpenStt} />
        <UploadPopUp openUpload={openUpload} setOpenUpload={setOpenUpload} />
      </GameContainer>
    </>
  )
}

export default BackendIntegratedEducationalChatGame;
